import React, { Component } from "react";
import "antd/dist/antd.css";
import { Table, Row, Col, Button, Menu, Dropdown} from "antd";
import { Link } from "react-router-dom";
import { list } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";

// const Search = Input.Search;
class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tablelength: 24,
      detailswidth: 0,
      subscriptions: [],
      // totalUsers: [],
      // selectedRecord:{}
    };
  }

  componentDidMount = () => {
    this.getSubscriptions();
  };

  getSubscriptions = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    let orgId = JSON.parse(localStorage.getItem("orgId"))
    let query={
      orgId
    }
    list(AppConfigs["entityNames"]["subscriptionPacks"],query).then((result) => {
      if (result.success) {
        // result.data.map((record) => {
        //   record["logo"] = Registeredlogo;
        //   return record;
        // })
        this.setState({
          subscriptions: result.data,
          dataLoadingStatus: false,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };
  editCell = (record) => {
    console.log(record,"hhh");
    this.props.history.push("/application/subscriptionOperations", record);
  };
  render() {
    const columns = [
      {
        title: "User Type",
        dataIndex: "userType",
        key: "userType",
        show: true,
        render: (userType, record) => {
          return (
            <span>
              {userType === "AG"
                ? "Agent"
                : userType === "L"
                ? "Lender"
                : "Vendor"}
            </span>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "planTitle",
        key: "name",
        show: true,
        render: (planTitle, record) => {
          return <span>{planTitle}</span>;
        },
      },
      {
        title: "Zipcodes",
        dataIndex: "zipcodes",
        key: "phzipcodesone",
        show: true,
        render: (zipcodes, record) => {
          return <span>{zipcodes}</span>;
        },
      },
      {
        title: "Specialites",
        dataIndex: "specialites",
        key: "specialites",
        show: true,
        render: (specialites, record) => {
          return <span>{specialites}</span>;
        },
      },
      {
        title: "Premier Status",
        dataIndex: "premierStatus",
        key: "premierStatus",
        show: true,
        render: (premierStatus, record) => {
          return <span>{premierStatus === "N" ? "No" : "Yes"}</span>;
        },
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        show: true,
        render: (price, record) => {
          return <span>{"$" + price}</span>;
        },
      },
      {
        title: 'DETAILS',
        key: 'id',
        dataIndex: 'id',
        show: this.state.show,
        render: (_,record) => (
          <Dropdown overlay={menu(record)} placement="bottomRight" className="orders-dropdown">
            <Button>...</Button>
          </Dropdown>
        )
      },
    ];
    const menu = (record) => (
      <Menu>
        <Menu.Item className='blue' >
          <span style={{ color: "#40a9ff", cursor: "pointer" }} onClick={() => this.editCell(record)}>Edit</span>
        </Menu.Item>
      </Menu>
    )
    return (
      <div className="registration">
        <div className="filter">
          <Row>
            <Col span={this.state.tablelength} style={{ padding: "30px" }}>
              <h1 className="registrationtext">
                Subscriptions &nbsp;&nbsp;
                <Link to="/application/subscriptionOperations">
                  <i className="fa fa-plus-circle"></i>
                </Link>
              </h1>
              <Row>
                {/* <Col span={7}>
                  <Search className="search" size="large"
                    placeholder="Search"
                    onChange={(event) => this.searchData(event, "text")}
                  />
                </Col> */}
              </Row>
              <Table
                columns={columns.filter((col) => col.show !== false)}
                dataSource={this.state.subscriptions}
                loading={this.state.dataLoadingStatus}
                rowKey={(record) => record.id}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default Subscriptions;
