import Dashboard from "../Containers/Dashboard/Dashboard";
import Users from "../Containers/Users/Users";
import UserDetails from "../Containers/Users/UserDetails";
import AddUser from "../Containers/Users/UserOperations";
import Advertisements from "../Containers/Ads/Advertisements";
import AdOperation from "../Containers/Ads/AdOperation";
import AddSubscriptions from "../Containers/Subscriptions/SubscriptionOperations";
import ContactForm from "../Containers/ContactForm/ContactForm";
import MissedLeads from "../Containers/MissedLeads/MissedLeads";
import AddApiFields from "../Containers/Configurations/AddApiFields";
import MortgageCal from "../Containers/Mortgage/MortgageCal";
import Organization from "../Containers/Organization/organization";
import Announcements from "../Containers/Announcements/Announcements";
import Add_announcement from "../Containers/Announcements/Add_announcement";
import AgentProfileEdit from "../Containers/Users/AgentProfileEdit";
import ShowAllLeads from "../Containers/allLeads/ShowAllLeads";
import ViewAllContact from "../Containers/allLeads/ViewLeadContact";
import Subscriptions from "../Containers/Subscriptions/Subscriptions";
import EmailVisitedContacts from "../Components/EmailVisitedContacts";
import PropertiesVisitedList from "../Components/PropertiesVisitedList";

const AppRoutes = [
  {
    key: "001",
    exact: true,
    path: "/application/users",
    name: "Users",
    component: Users,
  },
  {
    key: "002",
    exact: true,
    path: "/application/addUser",
    name: "Add New User",
    component: AddUser,
  },
  {
    key: "003",
    exact: true,
    path: "/application/advertisements",
    name: "Ads",
    component: Advertisements,
  },
  {
    key: "004",
    exact: true,
    path: "/application/addAdvertisement",
    name: "Add new ad",
    component: AdOperation,
  },
  {
    key: "005",
    exact: true,
    path: "/application/Announcements",
    name: "Announcements",
    component: Announcements,
  },
  {
    key: "006",
    exact: true,
    path: "/application/subscriptionOperations",
    name: "Add Subscriptions",
    component: AddSubscriptions,
  },
  {
    key: "007",
    exact: true,
    path: "/application/contactForm",
    name: "Contact Form Submissions",
    component: ContactForm,
  },
  {
    key: "008",
    exact: true,
    path: "/application/missedLeads",
    name: "Missed Leads",
    component: MissedLeads,
  },
  {
    key: "009",
    exact: true,
    path: "/application/configuration",
    name: "API configuration",
    component: AddApiFields,
  },
  {
    key: "010",
    exact: true,
    path: "/application/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    key: "011",
    exact: true,
    path: "/application/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    key: "012",
    exact: true,
    path: "/application/viewUser/:id",
    name: "UserDetails",
    component: UserDetails,
  },
  {
    key: "013",
    exact: true,
    path: "/application/mortgage",
    name: "UserDetails",
    component: MortgageCal,
  },
  {
    key: "014",
    exact: true,
    path: "/application/organization",
    name: "websites",
    component: Organization,
  },
  {
    key: "015",
    exact: true,
    path: "/application/addAnnouncement/:id",
    name: "Add new announcement",
    component: Add_announcement,
  },
  {
    key: "016",
    exact: true,
    path: "/application/addAnnouncement",
    name: "Add new announcement",
    component: Add_announcement,
  },
  {
    key: "017",
    exact: true,
    path: "/application/editAgentProfile/:id",
    name: "edit profile",
    component: AgentProfileEdit,
  },
  {
    key: "018",
    exact: true,
    path: "/application/all_leads",
    name: "edit profile",
    component: ShowAllLeads,
  },
  {
    key: "019",
    exact: true,
    path: "/application/view_lead/:id",
    name: "edit profile",
    component: ViewAllContact,
  },
  {
    key: "020",
    exact: true,
    path: "/application/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
  },
  {
    key: "021",
    exact: true,
    path: "/application/emailVisitedContacts",
    name: "emailVisitedContacts",
    component: EmailVisitedContacts,
  },
  {
    key: "022",
    exact: true,
    path: "/application/PropertiesVisited",
    name: "PropertiesVisited",
    component: PropertiesVisitedList,
  },
];

export default AppRoutes;
