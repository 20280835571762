import AppConfig from './AppConfig';

let awsConfigure = {
    Auth: {
      mandatorySignIn: true,
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: `${AppConfig.APP_IDENTITY_POOL_ID}`,
      // REQUIRED - Amazon Cognito Region
      region: `${AppConfig.APP_COGNITO_REGION}`,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: `${AppConfig.APP_COGNITO_USER_POOL_ID}`,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: `${AppConfig.APP_CLIENT_ID}`,
    },
    Storage: {
      bucket: `${AppConfig.APP_S3_BUCKET}`,
      region: `${AppConfig.APP_S3_REGION}`,
      identityPoolId: `${AppConfig.APP_IDENTITY_POOL_ID}`,
    },
};
export default awsConfigure;