import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Row,
  Spin,
  Col,
  Button,
  Form,
  Input,
  Card,
  Select,
  notification,
  Upload,
} from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { Link } from "react-router-dom";
import Amplify, { Storage } from "aws-amplify";
import { UploadOutlined } from "@ant-design/icons";
import { add, update, list } from "../../api/Apis";
import AwsConfig from "../../Config/AwsConfig";
import AppConfigs from "../../Config/AppConfig";

Amplify.configure(AwsConfig);
// console.log(AwsConfig, "htt");
const { Option } = Select;
class AdOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoading: false,
      imageKeys: "",
      fileList: [],
      saveAdLoading: false,
      editData: "",
      ima: "",
      editDataShowFalse: false,
      adsType: "",
      whereToShow: [],
      setAdOptionType: "",
      stateCodes: [],
      newAdPlaces: {
        cityName: [],
        stateName: [],
        postalCode: [],
        visiblity: "",
        generalValue: [],
      },
    };
  }
  componentDidMount() {
    const editData = JSON.parse(localStorage.getItem("editAd"));
    let fileList = [];
    if (editData && editData !== "undefined") {
      fileList = [
        {
          uid: "0",
          // name: 'image.png',
          status: "done",
          url: `${editData && editData.imagePath}`,
        },
      ];
      this.setState({
        imageKeys: editData && editData.image,
        editData: editData && editData,
        editDataShowFalse: true,
        fileList,
        adsType: editData && editData.imageType && editData.imageType,
        whereToShow:
          editData && editData.whereToshow ? editData.whereToshow : [],
        newAdPlaces:
          editData &&
          editData.desiredAdOptionPlaces &&
          editData.desiredAdOptionPlaces,

        setAdOptionType:
          editData && editData.desiredAdOptionPlaces
            ? editData.desiredAdOptionPlaces &&
              editData.desiredAdOptionPlaces.global
              ? "global"
              : editData.desiredAdOptionPlaces.visiblity
            : "",
        generalData:
          editData && editData.desiredAdOptionPlaces
            ? editData.desiredAdOptionPlaces &&
              editData.desiredAdOptionPlaces.generalData
            : "",
        // generalValue:
        //   editData && editData.desiredAdOptionPlaces
        //     ? editData.desiredAdOptionPlaces &&
        //       editData.desiredAdOptionPlaces.generalValue
        //     : "",
      });
    }
    this.getApiDetails();
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values, "valuess", this.state.newAdPlaces);

      if (values["AdOptionType"] !== "global") {
        if (this.state.newAdPlaces["visiblity"] === "") {
          notification["error"]({
            message: `Please Select ${values["AdOptionType"]}`,
          });
          return true;
        }
      }
      let adsUrl = values.URL;
      let finalAdUrl = "";

      let httpUrl = adsUrl.replace("https://", "").replace("http://", "");

      finalAdUrl = `https://${httpUrl}`;

      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      const data = Object.assign({}, { entityData: {} });
      data.entityData.imageType = values.imageType;
      data.entityData.image = this.state.imageKeys;
      data.entityData.imageUrl = finalAdUrl;
      data.entityData.whereToshow = this.state.whereToShow;
      data.entityData.desiredAdOptionPlaces = this.state.newAdPlaces;
      // if (userDetails && userDetails.userProfile !== "S") {
      data["entityData"]["orgId"] = organizationId;
      // }
      console.log(data, ";;;;");
      if (
        data.entityData &&
        values &&
        data.entityData.imageType &&
        data.entityData.image &&
        data.entityData.imageUrl &&
        data.entityData.whereToshow.length > 0 &&
        data.entityData.desiredAdOptionPlaces
      ) {
        this.setState({
          saveAdLoading: true,
        });
        if (!this.state.editDataShowFalse) {
          add("ads", data).then(
            (result) =>
              result.success &&
              setTimeout(
                () =>
                  notification.open({
                    message: "Advertisement created",
                    description: "Advertisement created successfully",
                  }),
                this.setState(
                  {
                    saveAdLoading: false,
                  },
                  () => this.props.history.push("/application/advertisements")
                ),
                8000
              )
          );
        } else {
          update("ads", this.state.editData && this.state.editData.id, data)
            .then(
              (result) =>
                result.success &&
                setTimeout(
                  () =>
                    notification.open({
                      message: "Advertisement updated",
                      description: "Advertisement updated successfully",
                      onClick: () => {
                        this.props.history.push("/application/advertisements");
                        // window.location.reload(true)
                      },
                    }),
                  this.setState(
                    {
                      saveAdLoading: false,
                    },
                    () => this.props.history.push("/application/advertisements")
                  ),
                  8000
                )
            )
            // console.log(res,"ressssssssss"))
            .catch((err) => console.log(err, "errrrrr"));
        }
      } else {
        this.setState({ saveAdLoading: false });
        // validateFields("imageUpload")
      }

      // const data = {
      //     "entityData": {
      //         imageType: "long rectangle",
      //         image: "",
      //         url: "",
      //         show: "",
      //     }
      // }
    });
  };
  getApiDetails = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    list(AppConfigs["entityNames"]["masterData"], {
      dataType: "apiConfig",
    }).then((result) => {
      if (result.success) {
        let stateCodes = [];
        result.data.forEach((el) => {
          if (
            stateCodes.filter((o) => o.stateType === el.stateType).length ===
              0 &&
            el.isActive
          ) {
            stateCodes.push({
              stateType: el.stateType,
              id: el.id,
              stateName: el.stateName,
            });
          }
          if (stateCodes.length === 0) {
            stateCodes.push({
              stateType: el.stateType,
              id: el.id,
              stateName: el.stateName,
            });
          }
        });
        console.log(stateCodes);
        this.setState({
          stateCodes,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };
  imageUpload = (info) => {
    this.setState({
      imageLoading: true,
    });
    let Images = "";
    const fileList = [];
    fileList.push(info);
    this.setState({
      fileList: info.fileList,
    });
    if (info.fileList.length > 0 && info.file.status !== "removed") {
      info.fileList.map((file) => {
        try {
          Storage.put(file.uid, file.originFileObj, {
            contentType: file.type,
          }).then((result) => {
            Images = result.key;
            this.setState({
              imageKeys: Images,
              imageLoading: false,
            });
          });
        } catch (err) {
          console.log(err, "errr");
        }
        return file;
      });
    }
    if (info.file.status === "removed") {
      this.setState({
        imageLoading: false,
      });
    }
  };

  handleToChangeAdsType = (adsType) => {
    this.setState({
      whereToShow: [],
      adsType,
    });
  };
  handleAdvertismentPage = (whereToShow) => {
    this.setState({
      whereToShow,
    });
  };
  handleAdOptionType = (setAdOptionType) => {
    this.setState({
      setAdOptionType,
    });

    // if (setAdOptionType === "global") {
    let newAdPlaces = {
      visiblity: "",
      cityName: [],
      postalCode: [],
      global: true,
      stateName: [],
      // generalValue: setAdOptionType,
      generalData: [],
    };
    this.setState({
      newAdPlaces,
    });
    // }
  };
  onSelect = (address, stateProp) => {
    const { setAdOptionType } = this.state;
    let cityValue = "";
    let stateValue = "";
    let zipCode = "";
    if (stateProp === "zipCity") {
      geocodeByAddress(address.label)
        .then((results) => {
          let address = results[0].address_components;
          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              cityValue = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              stateValue = address[i].short_name;
            }
            if (address[i].types[0] === "postal_code") {
              zipCode = address[i].short_name;
            }
          }

          let setVendor = this.state.stateCodes.filter(
            (restState) =>
              restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
              restState.stateType.toLowerCase() === stateValue.toLowerCase()
          );
          let newAdPlaces = {};
          if (setVendor && setVendor.length > 0) {
            if (setAdOptionType === "city") {
              newAdPlaces = {
                // cityName: cityValue,
                // generalValue: cityValue,
                visiblity: setAdOptionType,
                stateName: [],
                postalCode: [],
                global: false,
                cityName: [cityValue],
                generalData: [cityValue],
              };
            } else if (setAdOptionType === "zipCode") {
              newAdPlaces = {
                // postalCode: zipCode,
                // generalValue: zipCode,
                visiblity: setAdOptionType,
                stateName: [],
                cityName: [],
                global: false,
                postalCode: [zipCode],
                generalData: [zipCode],
              };
            } else {
              newAdPlaces = {
                global: true,
                visiblity: setAdOptionType,
                postalCode: [],
                stateName: [],
                cityName: [],
              };
            }
            this.setState({
              newAdPlaces,
            });
          } else {
            notification["error"]({
              message: "Please enter valid city...",
              description: "You can search cities from MA, CT, RI, NH states",
              placement: "topLeft",
            });
          }
        })
        .catch((error) => console.error("Error", error));
    } else {
      let newAdPlaces = {};

      newAdPlaces = {
        visiblity: setAdOptionType,
        cityName: [],
        postalCode: [],
        global: false,
        // stateName: stateValue,
        // generalValue: stateValue,
        stateName: [address],
        generalData: [address],

        // Multi data ...this.state.newAdPlaces.stateName,
      };
      this.setState({
        newAdPlaces,
      });
    }
  };

  //   onClose = (value) => {
  //     const { setAdOptionType, generalData } = this.state;
  //     let newAdPlaces = {};
  //     let generalDataRemove = generalData.filter(
  //       (removeItem) => removeItem !== value
  //     );
  // console.log(generalDataRemove,"khkajhds");
  //     this.setState({
  //       generalData: generalDataRemove,
  //     });
  //     if (setAdOptionType === "city") {
  //       newAdPlaces = {
  //         cityName: generalDataRemove,
  //         visiblity: setAdOptionType,
  //         stateName: null,
  //         global: null,
  //         postalCode: null,
  //       };
  //     } else if (setAdOptionType === "state") {
  //       newAdPlaces = {
  //         stateName: generalDataRemove,
  //         visiblity: setAdOptionType,
  //         cityName: null,
  //         global: null,
  //         postalCode: null,
  //       };
  //     } else if (setAdOptionType === "zipCode") {
  //       newAdPlaces = {
  //         postalCode: generalDataRemove,
  //         visiblity: setAdOptionType,
  //         stateName: null,
  //         cityName: null,
  //         global: null,
  //       };
  //     }
  //     console.log(newAdPlaces,"jjj");
  //     this.setState({
  //       newAdPlaces,
  //     });
  //   };
  render() {
    const squareTypeAds = [
      { key: 1, label: "Homes", value: "Homes" },
      { key: 2, label: "Buyers", value: "Buyers" },
      { key: 3, label: "Agents", value: "Agents" },
      { key: 4, label: "Lenders", value: "Lenders" },
      { key: 5, label: "Market Place", value: "MarketPlace" },
      { key: 6, label: "Favorites", value: "Favorites" },
      { key: 7, label: "Detail", value: "Detail" },
    ];
    const rectangleTypeAds = [
      { key: 1, label: "Login", value: "Login" },
      { key: 2, label: "Signup", value: "Signup" },
      { key: 3, label: "Footer", value: "Footer" },
      { key: 4, label: "Homes", value: "Homes" },
      { key: 5, label: "Buyers", value: "Buyers" },
      { key: 6, label: "Favorites", value: "Favorites" },
      { key: 7, label: "Pre Approved Popup", value: "PreApprovedPopup" },
      { key: 8, label: "Buyers Agent Popup", value: "BuyersAgentPopup" },
      { key: 9, label: "Mortgage Calculator", value: "MortgageCalculator" },
    ];
    const AdOptionType = [
      { key: 1, label: "Global", value: "global" },
      { key: 2, label: "State", value: "state" },
      { key: 3, label: "City", value: "city" },
      { key: 4, label: "Zip code", value: "zipCode" },
    ];
    const { getFieldDecorator } = this.props.form;
    const {
      imageLoading,
      fileList,
      saveAdLoading,
      adsType,
      setAdOptionType,
      newAdPlaces,
      stateCodes,
      whereToShow,
    } = this.state;

    return (
      <div style={{ marginTop: "5vw" }}>
        <Row>
          <Col
            xxl={{ span: 8, offset: 8 }}
            xl={{ span: 10, offset: 7 }}
            lg={{ span: 12, offset: 6 }}
          >
            <Form>
              <Card
                title="Add Advertisement Details"
                style={{ borderRadius: "10px" }}
              >
                <Row gutter={10}>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Advertisement type
                        </span>
                      }
                    >
                      {getFieldDecorator("imageType", {
                        rules: [
                          {
                            required: true,
                            message: "Please select type",
                          },
                        ],
                        initialValue: `${
                          this.state.editData && this.state.editData.imageType
                            ? this.state.editData.imageType
                            : ""
                        }`,
                      })(
                        <Select onChange={this.handleToChangeAdsType}>
                          <Option key="H" value="Rectangle">
                            Rectangle
                          </Option>
                          <Option key="L" value="Square">
                            Square
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    {/* <Form.Item className="Formitem"
                                    label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Upload Image</span>}>
                                    {getFieldDecorator('lastName', {
                                        rules: [{ required: true, message: 'Please input Last Name!' }],
                                    })(<Input />)}
                                </Form.Item> */}
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Upload Image
                        </span>
                      }
                    >
                      <Spin spinning={imageLoading}>
                        {getFieldDecorator("imageUpload", {
                          rules: [
                            {
                              required: true,
                              message: "Please upload advertisement image!",
                            },
                          ],
                          initialValue: `${
                            this.state.editData && this.state.editData.imagePath
                              ? this.state.editData.imagePath
                              : ""
                          }`,
                        })(
                          <Upload
                            // {...props}
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            // listType="picture"
                            // defaultFileList={[]}
                            onChange={this.imageUpload}
                            listType="picture"
                            fileList={fileList}
                            beforeUpload={() => false}
                          >
                            {fileList.length < 1 && (
                              <Button>
                                <UploadOutlined />
                                {fileList.length < 1 && "Upload Image"}
                              </Button>
                            )}
                          </Upload>
                        )}
                      </Spin>
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Destination URL
                        </span>
                      }
                    >
                      {getFieldDecorator("URL", {
                        rules: [
                          {
                            required: true,
                            message:
                              "Please input advertisement destination url!",
                          },
                        ],
                        initialValue: `${
                          this.state.editData
                            ? this.state.editData.imageUrl
                            : ""
                        }`,
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          On page
                        </span>
                      }
                    >
                      {getFieldDecorator("whereToShow", {
                        rules: [
                          {
                            required: true,
                            message: "Please select where to show your ad!",
                          },
                        ],
                        initialValue: this.state.whereToShow
                          ? this.state.whereToShow
                          : [],
                      })(
                        adsType === "Square" ? (
                          <Select
                            mode="multiple"
                            // ={
                            //   this.state.whereToShow ? this.state.whereToShow
                            //     : []
                            // }
                            onChange={this.handleAdvertismentPage}
                          >
                            {squareTypeAds.map((province) => (
                              <Select.Option
                                key={province.value}
                                value={province.value}
                              >
                                {province.label}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Select
                            mode="multiple"
                            // defaultValue={
                            //   this.state.whereToShow ? this.state.whereToShow
                            //     : []
                            // }
                            onChange={this.handleAdvertismentPage}
                          >
                            {rectangleTypeAds.map((province) => (
                              <Select.Option
                                key={province.value}
                                value={province.value}
                              >
                                {province.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )
                      )}
                    </Form.Item>
                  </Col>

                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Visibility
                        </span>
                      }
                    >
                      {getFieldDecorator("AdOptionType", {
                        rules: [
                          {
                            required: true,
                            message: "Please select visible place",
                          },
                        ],
                        initialValue: setAdOptionType ? setAdOptionType : "",
                      })(
                        <Select
                          // mode="multiple"
                          onChange={this.handleAdOptionType}
                        >
                          {AdOptionType.map((province) => (
                            <Select.Option
                              key={province.value}
                              value={province.value}
                              disabled={
                                ((whereToShow.includes("Login") &&
                                  whereToShow.includes("Signup") &&
                                  whereToShow.length === 2) ||
                                  ((whereToShow.includes("Login") ||
                                    whereToShow.includes("Signup")) &&
                                    whereToShow.length === 1)) &&
                                province.value !== "global"
                                  ? true
                                  : false
                              }
                            >
                              {province.label}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  {setAdOptionType !== "" && setAdOptionType !== "global" && (
                    <Col lg={24} md={24} sm={24} sx={24}>
                      <Form.Item
                        // className="Formitem"
                        label={
                          <span style={{ color: "#7B8190", fontSize: "14px" }}>
                            Desired {setAdOptionType}
                          </span>
                        }
                      >
                        {setAdOptionType !== "state" ? (
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              // types: ["(cities)"],
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              placeholder: "Enter...",
                              defaultInputValue:
                                newAdPlaces && newAdPlaces.generalData
                                  ? newAdPlaces.generalData[0]
                                  : "",
                              onChange: (result) => {
                                this.onSelect(result, "zipCity");
                              },
                              style: { padding: "2%" },
                            }}
                          />
                        ) : (
                          <Select
                            // mode="multiple"
                            defaultValue={
                              newAdPlaces && newAdPlaces.generalData
                                ? newAdPlaces.generalData[0]
                                : ""
                            }
                            onChange={(value) => this.onSelect(value, "")}
                          >
                            {stateCodes.map((province) => (
                              <Select.Option
                                key={province.id}
                                value={province.stateType}
                              >
                                {province.stateType}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                      {/* {generalData &&
                        generalData.map((eachItem, index) => (
                          <div
                            key={index}
                            style={{
                              display: "inline-flex",
                              margin: "0.73vw",
                              padding: "0 7px",
                              flexWrap: "wrap",
                              justifyContent: "space-around",
                              border: "1px solid darkgrey",
                            }}
                          >
                            <div>{eachItem}</div>
                            <div
                              style={{
                                color: "grey",
                                cursor: "pointer",
                                marginLeft: "0.4vw",
                              }}
                              onClick={() => this.onClose(eachItem)}
                            >
                              x
                            </div>
                          </div>
                        ))} */}
                    </Col>
                  )}
                </Row>
              </Card>
              <Row style={{ marginTop: "1vw" }}>
                <Spin spinning={saveAdLoading}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#25559517",
                        color: "#fff",
                        marginRight: "30px",
                        padding: "0 20px 0 20px",
                        border: "none",
                      }}
                    >
                      <Link to="/application/advertisements">Cancel</Link>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={this.handleSubmit}
                      style={{
                        float: "right",
                        backgroundColor: "#255595",
                        color: "white",
                        padding: "0 20px 0 20px",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Spin>
              </Row>
            </Form>
            {/* </div>
        </Col> */}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create({ name: "adduser" })(withRouter(AdOperation));
