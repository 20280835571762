import React from "react";
import { Table, Input, Button, Select, Col, Row, message, Modal } from "antd";
import { Link } from "react-router-dom";
import { list, update } from "../../api/Apis";
import reassign from "../../Images/reassign.png";
const { Option } = Select;
const Search = Input.Search;

class ShowAllLeads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 2,
      totalContacts: "",
      filterContacts: "",
      reAssign_lead: false,
      agentsList: [],
      assign_record: "",
    };
  }

  componentDidMount = () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    this.getAllAgents(orgId);
    this.getAllContactLeads(orgId);
  };

  // GET ALL CONTACT LEADS RECORDS
  getAllContactLeads = (orgId) => {
    let query = {
      contactType: "Contact",
      orgId,
    };
    list("virtualOfficeContacts", query)
      .then((result) => {
        if (result.success) {
          this.setState({
            totalContacts: result.data,
            filterContacts: result.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //GET AGENTS LIST FOR ASSIGN LEAD
  getAllAgents = async (orgId) => {
    let query = {
      userProfile: "AG",
      orgId,
    };
    list("users", query).then((result) => {
      if (result.success) {
        let agentsList = result.data.map((user) => {
          return {
            value: user.id,
            label: user.firstName + " " + user.lastName,
            email: user.email,
            userData: user,
          };
        });
        this.setState({
          agentsList,
        });
      }
    });
  };
  // SAVE ASSIGN LEAD CHANGES
  handle_reassing_lead = () => {
    const { assign_lead_details, assign_record } = this.state;
    let adminOrgId = JSON.parse(localStorage.getItem("orgId"));

    let agentDetails = {
      full_name: assign_lead_details.label,
      agent_id: assign_lead_details.value,
      email: assign_lead_details.email,
      orgId: assign_lead_details.userData?.orgId,
    };
    let data = {
      entityData: {
        agentDetails,
      },
    };
    update("virtualOfficeContacts", assign_record.id, data)
      .then((result) => {
        if (result.success) {
          message.success("Re assigned to new agent... !");
          this.setState({
            reAssign_lead: false,
            assign_record: "",
          });
          this.getAllContactLeads(adminOrgId);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          reAssign_lead: false,
        });
      });
    console.log(assign_lead_details, ";;;");
  };

  onReferedBySelectAgent = (id) => {
    const assign_lead_details = this.state.agentsList.filter(
      (agent) => agent.value === id
    )[0];

    this.setState({
      assign_lead_details,
    });
  };
  // OPEN ASSIGN LEAD POP UP
  open_re_assign_lead = () => {
    const { agentsList, assign_record } = this.state;
    console.log(assign_record, ";assign_recordassign_record");
    return (
      <>
        <Row style={{ padding: "5px" }}>
          <Col span={24}>
            <Row>
              <h4 style={{ color: "#aaa", marginTop: "0px" }}>
                Assign to the Agent
              </h4>
              {assign_record?.agentDetails && (
                <Select
                  style={{ width: "100%" }}
                  className="input-width"
                  showSearch
                  optionFilterProp="children"
                  options={agentsList}
                  onSelect={(e) => this.onReferedBySelectAgent(e)}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) !== -1
                  }
                  // value={}
                  defaultValue={assign_record?.agentDetails?.full_name}
                >
                  {agentsList.map((agent) => (
                    <Option key={agent.value} value={agent.value}>
                      {agent.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { filterContacts, reAssign_lead, assign_record } = this.state;

    // TABLE COLUMNS
    const columns = [
      {
        title: "Agent Name",
        dataIndex: "agentDetails",
        key: "agentDetails",
        sorter: true,
        render: (agentDetails) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {agentDetails.full_name}
            </span>
          );
        },
      },
      {
        title: "Cilents/Prospect Name ",
        dataIndex: "prospectName",
        key: "prospectName",
        sorter: true,
        render: (prospectName) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {prospectName}
            </span>
          );
        },
      },
      {
        title: "Phone #",
        dataIndex: "primaryPhone",
        key: "primaryPhone",
        sorter: true,
        render: (primaryPhone) => {
          return <span>{primaryPhone}</span>;
        },
      },
      {
        title: "Email",
        dataIndex: "primaryEmail",
        key: "primaryEmail",
        sorter: true,
        render: (primaryEmail) => {
          return <span>{primaryEmail}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "listingStatus",
        key: "listingStatus",
        sorter: true,

        render: (listingStatus) => {
          return <span>{listingStatus}</span>;
        },
      },
      {
        title: "Rating",
        dataIndex: "rating",
        key: "rating",
        sorter: true,
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({ modalVisible: true, viewProperty: record }),
        // }),
        render: (rating) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              {rating && rating.charAt(0)}
            </span>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "typeOfClient",
        key: "typeOfClient",
        sorter: true,
        // onCell: (record) => ({
        //   onClick: () =>
        //     this.setState({ modalVisible: true, viewProperty: record }),
        // }),
        render: (typeOfClient) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {typeOfClient}
            </span>
          );
        },
      },
      {
        title: "Notes",
        dataIndex: "pulbicNotes",
        key: "pulbicNotes",
        sorter: true,

        render: (pulbicNotes) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {pulbicNotes}
            </span>
          );
        },
      },
      {
        title: "View",
        dataIndex: "businessCard",
        key: "businessCard",
        sorter: false,
        render: (businessCard, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              <Link to={`/application/view_lead/${record.id}`}>
                <span
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#5bc0de",
                    fontWeight: "bold",
                    padding: "5px 30px",
                    color: "#fff",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  View
                </span>
              </Link>
            </span>
          );
        },
      },
      {
        title: "Re assign lead",
        dataIndex: "id",
        key: "id",
        sorter: false,
        render: (id, record) => {
          return (
            <span>
              <img
                src={reassign}
                alt="reassign_lead"
                width="30px"
                height="30px"
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={() =>
                  this.setState({
                    reAssign_lead: true,
                    assign_record: record,
                  })
                }
              />
            </span>
          );
        },
      },
    ];

    return (
      <div className="registration">
        <Row style={{ marginTop: "2vw" }}>
          <Col span={24} style={{ padding: "10px" }}>
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <Search
                  className="search"
                  size="large"
                  placeholder="Search"
                  onChange={(event) =>
                    this.searchData(event, "text", "searchVal")
                  }
                  value={this.state.searchVal}
                />
              </Col>

              <Col span={3} offset={14} style={{ display: "flex" }}>
                <Button
                  type="primary"
                  size="default"
                  style={{ width: "100%", height: "40px" }}
                >
                  <Link to="/application/addUser">
                    <h1 style={{ color: "#fff", marginTop: "5px" }}>
                      <i className="fa fa-plus-circle"></i> Lead{" "}
                    </h1>
                  </Link>
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={filterContacts}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "25", "50", "100"],
                total: filterContacts.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              size="middle"
            />
          </Col>
          <Modal
            className="modal-black-close"
            visible={reAssign_lead}
            title={"Re assign lead"}
            onCancel={() =>
              this.setState({
                reAssign_lead: false,
                assign_record: "",
              })
            }
            footer={[
              <Button
                key="back"
                onClick={() =>
                  this.setState({
                    reAssign_lead: false,
                    assign_record: "",
                  })
                }
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => this.handle_reassing_lead()}
                htmlType="submit"
              >
                Assign
              </Button>,
            ]}
          >
            {this.open_re_assign_lead(assign_record)}
          </Modal>
        </Row>
      </div>
    );
  }
}

export default ShowAllLeads;
