import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Row,
  Spin,
  Col,
  Button,
  Form,
  Input,
  Card,
  Upload,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import Amplify, { Storage } from "aws-amplify";
import { UploadOutlined } from "@ant-design/icons";
import { add, update, list, getDetails } from "../../api/Apis";
import AwsConfig from "../../Config/AwsConfig";
import AppConfigs from "../../Config/AppConfig";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

Amplify.configure(AwsConfig);
// console.log(AwsConfig, "htt");
const { RangePicker } = DatePicker;
class Add_announcement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoading: false,
      imageKeys: "",
      fileList: [],
      saveAdLoading: false,
      editData: "",
      ima: "",
      editDataShowFalse: false,
      adsType: "",
      whereToShow: [],
      setAdOptionType: "",
      stateCodes: [],
      startDate: "",
      endDate: "",
      newAdPlaces: {
        cityName: [],
        stateName: [],
        postalCode: [],
        visiblity: "",
        generalValue: [],
      },
    };
  }
  componentDidMount = async () => {
    // const editData = JSON.parse(localStorage.getItem("editAnnouncement"));
    let fileList = [];
    // if (editData && editData !== "undefined") {
    let editData = await getDetails(
      "virtualOfficeAnnouncement",
      this.props.match.params.id
    );
    editData = editData.data;
    if (editData !== "") {
      fileList = [
        {
          uid: "0",
          // name: 'image.png',
          status: "done",
          url: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${editData?.profileImageUpload}`,
        },
      ];

      this.setState({
        imageKeys: editData && editData.profileImageUpload,
        editData,
        editDataShowFalse: true,
        fileList,
        startDate:
          editData &&
          editData.start_and_end_date.length &&
          moment(editData.start_and_end_date[0]).format("L"),
        endDate:
          editData &&
          editData.start_and_end_date.length &&
          moment(editData.start_and_end_date[1]).format("L"),
      });
    }
    this.getApiDetails();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { imageKeys, startDate, endDate } = this.state;
    const organizationId = JSON.parse(localStorage.getItem("orgId"))
    this.props.form.validateFields((err, values) => {
      const data = Object.assign({}, { entityData: {} });
      values["profileImageUpload"] = imageKeys !== "" && imageKeys;
      values["start_and_end_date"] = [startDate, endDate];
      values["orgId"] = organizationId
      data["entityData"] = values;

      try {
        if (window.location.pathname === "/application/addAnnouncement") {
          add("virtualOfficeAnnouncement", data).then((res) => {
            window.location.assign("/application/Announcements");
          });
        } else {
          update(
            "virtualOfficeAnnouncement",
            this.props.match.params.id,
            data
          ).then((res) => {
            window.location.assign("/application/Announcements");
          });
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({ saveAdLoading: false });
      // validateFields("imageUpload")
    });
  };
  handleStartAndEndDate = (date) => {
    this.setState({
      startDate: moment(date[0]).format("MM/DD/YYYY"),
      endDate: moment(date[1]).format("MM/DD/YYYY"),
    });
  };
  getApiDetails = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    list(AppConfigs["entityNames"]["masterData"], {
      dataType: "apiConfig",
    }).then((result) => {
      if (result.success) {
        this.setState({
          stateCodes: result.data,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };

  imageUpload = (info) => {
    this.setState({
      imageLoading: true,
    });
    let Images = "";
    const fileList = [];
    fileList.push(info);
    this.setState({
      fileList: info.fileList,
    });
    if (info.fileList.length > 0 && info.file.status !== "removed") {
      info.fileList.map((file) => {
        try {
          Storage.put(file.uid, file.originFileObj, {
            contentType: file.type,
          }).then((result) => {
            Images = result.key;
            this.setState({
              imageKeys: Images,
              imageLoading: false,
            });
          });
        } catch (err) {
          console.log(err, "errr");
        }
        return file;
      });
    }
    if (info.file.status === "removed") {
      this.setState({
        imageLoading: false,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      imageLoading,
      fileList,
      saveAdLoading,
      editData,
      startDate,
      endDate,
    } = this.state;
    return (
      <div
        style={{
          position: "absolute",
          top: "35px",
          paddingLeft: "25%",
          paddingRight: "25%",
        }}
      >
        <Col lg={12} md={12} sm={24} xs={24} offset={6}>
          <div className="site-card-border-less-wrapper">
            <Form
            // onSubmit={this.handleSubmit}
            >
              <Card
                title="Add Announcement Details"
                style={{ borderRadius: "10px" }}
              >
                <Row gutter={10}>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Title
                        </span>
                      }
                    >
                      {getFieldDecorator("title", {
                        rules: [
                          {
                            required: true,
                            message: "Please input announcement title!",
                          },
                        ],
                        initialValue: `${editData ? editData.title : ""}`,
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Description
                        </span>
                      }
                    >
                      {getFieldDecorator("description", {
                        rules: [
                          {
                            required: true,
                            message: "Please input description !",
                          },
                        ],
                        initialValue: `${editData ? editData.description : ""}`,
                      })(<TextArea />)}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Date
                        </span>
                      }
                    >
                      {window.location.pathname !==
                      "/application/addAnnouncement" ? (
                        startDate &&
                        endDate !== "" && (
                          <RangePicker
                            defaultValue={
                              startDate !== "" && [
                                moment(
                                  startDate,

                                  "MM/DD/YYYY"
                                ),
                                moment(endDate, "MM/DD/YYYY"),
                              ]
                            }
                            size={"default"}
                            format="MM/DD/YYYY"
                            onChange={this.handleStartAndEndDate}
                          />
                        )
                      ) : (
                        <RangePicker
                          size={"default"}
                          format="MM/DD/YYYY"
                          onChange={this.handleStartAndEndDate}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Upload Image
                        </span>
                      }
                    >
                      <Spin spinning={imageLoading}>
                        {getFieldDecorator("profileImageUpload", {
                          rules: [
                            {
                              required: true,
                              message: "Please upload profile !",
                            },
                          ],
                          initialValue:{fileList}
                        })(
                          <Upload
                            onChange={this.imageUpload}
                            listType="picture"
                            fileList={fileList}
                            beforeUpload={() => false}
                          >
                            {fileList.length < 1 && (
                              <Button>
                                <UploadOutlined />
                                {fileList.length < 1 && "Upload Image"}
                              </Button>
                            )}
                          </Upload>
                        )}
                      </Spin>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Spin spinning={saveAdLoading}>
                  <Form.Item style={{ marginTop: "5%" }}>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#25559517",
                        marginRight: "30px",
                        padding: "0 20px 0 20px",
                        border: "none",
                        color: "white",
                      }}
                    >
                      <Link to="/application/Announcements">Cancel</Link>
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={this.handleSubmit}
                      style={{
                        float: "right",
                        backgroundColor: "#255595",
                        color: "white",
                        padding: "0 20px 0 20px",
                      }}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Spin>
              </Row>
            </Form>
          </div>
        </Col>
      </div>
    );
  }
}

export default Form.create({ name: "adduser" })(withRouter(Add_announcement));
