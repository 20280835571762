import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Row,
  Col,
  Popconfirm,
  notification,
  Button,
  Menu,
  Dropdown,
  Card,
} from "antd";
import Amplify, { Storage } from "aws-amplify";
import { list, deleteRecord } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";
import AwsConfig from "../../Config/AwsConfig";
import { Link } from "react-router-dom";
import moment from "moment";
Amplify.configure(AwsConfig);

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tablelength: 24,
      detailswidth: 0,
      users: [],
      totalUsers: [],
      selectedRecord: {},
      resultImage: [],
      data: [],
      newImage: "",
      editCell: false,
      editData: "",
      oldImage: "",
      bigAds: [],
      smallAds: [],

      totalAnnouncements: [],
    };
  }
  componentDidMount = async () => {
    this.getUsers();
    this.getAnnouncements();
  };
  getAnnouncements = () => {
    list("virtualOfficeAnnouncement")
      .then((res) =>
        this.setState({
          totalAnnouncements: res.data,
        })
      )
      .catch((error) => console.log(error));
  };
  details = (record) => {
    this.setState({
      show: false,
      selectedRecord: record,
      tablelength: 14,
      detailswidth: 10,
    });
  };
  close = () => {
    this.setState({
      show: true,
      tablelength: 24,
      detailswidth: 0,
    });
  };

  getUsers = async () => {
    const data = [];
    let bigAdsList = [];
    let smallAdsList = [];

    this.setState({
      dataLoadingStatus: true,
    });
    let query = {};
    let adminDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (adminDetails && adminDetails["userProfile"] === "A") {
      query["orgId"] = adminDetails.id;
    }
    query["orgId"] = list(AppConfigs["entityNames"]["ads"], query).then(
      (result) => {
        if (result.success) {
          result.data.map(async (each) => {
            each.imageType === "Square"
              ? smallAdsList.push(each)
              : bigAdsList.push(each);

            let newImage = await Storage.get(each.image);
            data.push({
              imageType: each.imageType,
              imageUrl: each.imageUrl,
              whereToshow: each.whereToshow,
              image: each.image,
              imagePath: newImage,
              createdAt: each.createdAt,
              id: each.id,
              updatedAt: each.updatedAt,
              desiredAdOptionPlaces: each.desiredAdOptionPlaces
                ? each.desiredAdOptionPlaces
                : {},
            });
            this.setState({ data, bigAds: bigAdsList, smallAds: smallAdsList });
          });
          this.setState({
            users: result.data,
            data: data,
            dataLoadingStatus: false,
          });
        } else {
          this.setState({
            dataLoadingStatus: false,
          });
          console.log(result.errors);
        }
      }
    );
  };
  getnewDetails = (each) => {
    console.log(each.image, "each");
  };
  editCell = (id) => {
    this.setState({
      editCell: true,
      editData: id,
    });
    localStorage.setItem("editAnnouncement", JSON.stringify(id));
    this.props.history.push(`/application/addAnnouncement/${id.id}`, id);
  };
  handleDelete = (id) => {
    deleteRecord("virtualOfficeAnnouncement", id).then((result) => {
      if (result.success) {
        this.setState({
          dataLoadingStatus: true,
        });
        notification.open({
          message: "Announcement deleted",
          description: "Announcement deleted successfully",
          onClick: () => {
            this.props.history.push("/application/Announcements");
          },
        });
        setTimeout(() => window.location.reload(true), 2000);
      }
    });
  };
  handleToAddnewAnnouncements = () => {
    localStorage.removeItem("editAnnouncement");
  };

  resultView = (type, icon, color, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        <Row gutter={[16, 16]}>
          <Col
            span={8}
            style={{
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <i
              class={`fa ${icon}`}
              style={{
                fontSize: "80px",
                color: color,
              }}
            ></i>
          </Col>
          <Col span={14} offset={2}>
            <div>
              <h4 className={type === "ads" ? "mid-title" : "sub-title"}>
                {title}
              </h4>
              <h1 className="result-small">{result}</h1>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const columns = [
      {
        title: "Profile",
        dataIndex: "profileImageUpload",
        key: "profileImageUpload",
        show: true,
        render: (profileImageUpload, record) => {
          return (
            <span>
              <img
                src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${profileImageUpload}`}
                width="70px"
                height="40px"
                alt="Logo"
              />
            </span>
          );
        },
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        show: true,
        render: (title) => {
          return <div>{title}</div>;
        },
      },
      {
        title: "Description ",
        dataIndex: "description",
        key: "description",
        show: true,
        render: (description, record) => {
          return <span>{description}</span>;
        },
      },
      {
        title: "Start date ",
        dataIndex: "start_and_end_date",
        key: "show",
        show: true,
        render: (start_and_end_date, record) => {
          return (
            <span>
              {start_and_end_date.length &&
                moment(start_and_end_date[0]).format("L")}
            </span>
          );
        },
      },
      {
        title: "End date",
        dataIndex: "start_and_end_date",
        key: "end_Date",
        show: true,
        render: (start_and_end_date, record) => {
          return (
            <span>
              {start_and_end_date.length &&
                moment(start_and_end_date[1]).format("L")}
            </span>
          );
        },
      },
      {
        title: "DETAILS",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (_, record) => (
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            className="orders-dropdown"
          >
            <Button>...</Button>
          </Dropdown>
        ),
      },
    ];
    const menu = (record) => (
      <Menu>
        <Menu.Item className="blue">
          <span
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => this.editCell(record)}
          >
            Edit
          </span>
        </Menu.Item>
        <Menu.Item className="blue">
          <Popconfirm
            title="Are you Sure!! you want to delete this ad?"
            onConfirm={() => this.handleDelete(record.id)}
          >
            <span style={{ cursor: "pointer", color: "#FF0000" }}>Delete</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="registration-add">
        <div className="filter">
          <Row style={{ marginTop: "2vw" }}>
            <Col span={this.state.tablelength} style={{ padding: "10px" }}>
              <div style={{ display: "flex" }}>
                <h1 className="registrationtext">
                  Announcements&nbsp;&nbsp;
                  <Link
                    to="/application/addAnnouncement"
                    onClick={this.handleToAddnewAnnouncements}
                  >
                    <i className="fa fa-plus-circle"></i>
                  </Link>
                </h1>
              </div>

              <Card>
                <Row></Row>
                <Table
                  columns={columns.filter((col) => col.show !== false)}
                  dataSource={this.state.totalAnnouncements}
                  loading={this.state.dataLoadingStatus}
                  rowKey={(record) => record.id}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default Announcements;
