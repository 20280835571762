import React, { Component } from "react";
import { Row, Col, Button, Form, Input, Card } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { add, noAuthList, update } from "../../api/Apis";

class MortgageCal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mortgage_interest: "",
      down_payment_percentage: 10,
      updateId: "",
    };
  }

  componentDidMount = async () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    let mortgage_details = await noAuthList("mortgageCalDefault",{orgId});
    if (mortgage_details.success) {
      this.setState({
        down_payment_percentage:
          mortgage_details?.data.length > 0
            ? mortgage_details.data[0]["down_payment_percentage"]
            : "",
        mortgage_interest:
          mortgage_details?.data.length > 0
            ? mortgage_details.data[0]["mortgage_interest"]
            : "",
        updateId:
          mortgage_details?.data.length > 0
            ? mortgage_details.data[0]["id"]
            : "",
      });
    }
  };

  handleSubmit = (e) => {
    const { mortgage_interest, updateId } = this.state;
    e.preventDefault();
    let orgId = JSON.parse(localStorage.getItem("orgId"));

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        let data = {
          entityData: {
            down_payment_percentage: values["down_payment_percentage"],
            mortgage_interest: values["mortgage_interest"],
            dataType: "mortgageDefault",
            orgId
          },
        };
        if (mortgage_interest && mortgage_interest !== "" && updateId !== "") {
          update("masterData", updateId, data).then((result) => {
            if (result.success) {
              this.setState({
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
            this.props.history.push("/application/users");
          });
        } else {
          add("masterData", data).then((result) => {
            if (result.success) {
              this.setState({
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
            this.props.history.push("/application/users");
          });
        }
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { down_payment_percentage, mortgage_interest } = this.state;
    return (
      <div style={{ marginTop: "5vw" }}>
        <Row>
          <Col
            xxl={{ span: 8, offset: 8 }}
            xl={{ span: 10, offset: 7 }}
            lg={{ span: 12, offset: 6 }}
          >
            <Form onSubmit={this.handleSubmit}>
              <Card title="Mortgage Calculator">
                <Row gutter={10}>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Interest
                        </span>
                      }
                    >
                      {getFieldDecorator("mortgage_interest", {
                        rules: [
                          {
                            required: true,
                            message: "Please input Interest!",
                          },
                        ],
                        initialValue: `${mortgage_interest}`,
                      })(<Input addonBefore={"%"} />)}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Down Payment
                        </span>
                      }
                    >
                      {getFieldDecorator("down_payment_percentage", {
                        rules: [
                          {
                            required: true,
                            message: "Please input down payment!",
                          },
                        ],
                        initialValue: `${down_payment_percentage}`,
                      })(<Input addonBefore={"%"} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#255595",
                        color: "white",
                        marginRight: "30px",
                        padding: "0 20px 0 20px",
                        border: "none",
                        height: "40px",
                      }}
                    >
                      <Link to="/application/users">Cancel</Link>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                      onClick={this.handleSubmit}
                      style={{
                        float: "right",
                        backgroundColor: "#255595",
                        color: "white",
                        padding: "0 20px 0 20px",
                        height: "40px",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Form.create({ name: "mortgage" })(withRouter(MortgageCal));
