import {
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Button,
  Spin,
  Select,
  DatePicker,
  Checkbox
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { update, getDetails, noAuthList } from "../../api/Apis";
import RicheText from "./RicheText";
import AddZipCodes from "./AddZipCodes";

const { Option } = Select;
class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: {
        cities: [],
        type: "",
      },
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      saveDetailsFalse: false,
      zipCode: "",
      serviceAreas: [],
      spec: 0,
      zip: "",
      Ziplist: [],
      Addmore: false,
      speclist: [],
      optionsSelected: [],
      zipCount: 0,
      showSubscriptions: false,
      dataLoadingFalse: false,
      richtTextBiofield: "",
      allUsersList: [],
      referedById: "",
      referedByName: "",
      handleZipErrorMsg: false,
      agentsList: [],
      userInput: false,
      addUserName: "",
      addUserEmail: "",
      agentTowns: [],
      agentPortfolio: "",
      oldCategories: [],
      loadTotalData: false,
      updateAgentPortfolio: false,
      lendersList: [],
      lenderInfo: [],
      loadSelect: false,
      mlsStateId: "",
      mlsState: [],
      mlsSateList: [],
      mlsIdsUpdateStatus: false,
      userDetails: {},
      allZipCodes:false
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getAllUsers();
    this.getAllAgents();
    this.getRefreshInfo();
    this.getPortfoliosList();
    this.getAllLenders();
    this.getStateCodesApi();
  }
  getStateCodesApi = () => {
    try {
      const dataObj = {
        dataType: "apiConfig",
      };
      noAuthList("getConfigData", dataObj)
        .then((stateCodes) => {
          if (stateCodes.success) {
            localStorage.setItem(
              "stateCodesFromAdmin",
              JSON.stringify(stateCodes.data)
            );
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  getPortfoliosList = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    try {
      const response = await noAuthList(
        "testimonials?userId=" + userDetails.id + "&dataType=portfolio"
      );
      if (response.success) {
        if (response.data.length > 0) {
          this.setState({
            updateAgentPortfolio: true,
          });
        }
        const oldCategories = [];
        response.data.map((eachPortfolio) =>
          oldCategories.push({
            label: eachPortfolio.portfolioName,
            id: eachPortfolio.id,
          })
        );
        this.setState({
          oldCategories,
          loadTotalData: true,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };
  getRefreshInfo = () => {
    const id = this.props.match.params.id;
    if (id) {
      getDetails("users", id)
        .then((responce) => {
          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.serviceAreas
          ) {
            const serviceAreas = responce.data.newdata.serviceAreas.filter(
              (checkObject) => typeof checkObject !== "string"
            );
            this.setState({
              serviceAreas: serviceAreas,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.serviceAreas
          ) {
            this.setState({
              allZipCodes:responce.data.showAllZipCodes
            })
          }

          if (
            responce.data &&
            responce.data.mlsSateList &&
            responce.data.mlsSateList.length > 0
          ) {
            this.setState({
              mlsSateList: responce.data.mlsSateList,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.Specialties
          ) {
            this.setState({
              optionsSelected: responce.data.newdata.Specialties,
            });
          }
          if (
            responce.data &&
            responce.data.agentInfo &&
            responce.data.agentInfo.agentEmail &&
            responce.data.agentInfo.agentName
          ) {
            this.setState({
              addUserEmail: responce.data.agentInfo.agentEmail,
              addUserName: responce.data.agentInfo.agentName,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.zipCodes &&
            responce.data.newdata.zipCodes
          ) {
            this.setState({
              Ziplist: responce.data.newdata.zipCodes,
            });
          }

          if (
            responce.data &&
            responce.data.newdata &&
            responce.data.newdata.agentTowns &&
            responce.data.newdata.agentTowns.length > 0
          ) {
            this.setState({
              agentTowns: responce.data.newdata.agentTowns,
            });
          }
          if (responce.data && responce.data.lenderInfo) {
            this.setState({
              lenderInfo: responce.data.lenderInfo,
              loadSelect: true,
            });
          } else {
            this.setState({
              loadSelect: true,
            });
          }
          this.setState({
            userDetails: responce?.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getAllUsers = async () => {
    try {
      const usersList = await noAuthList("getUsers");
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  getAllAgents = async () => {
    try {
      const AllagentsList = await noAuthList("getAgents");
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };
  getAllLenders = async () => {
    try {
      const usersList = await noAuthList("getUsers?userProfile=L");
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (usersList.success) {
        this.setState({
          lendersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };
  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    }
    //  else {
    //   const Ziplist = [...this.state.Ziplist];
    //   Ziplist.push(value.target.value);
    //   this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    // }
  };

  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.updateDetails(e);
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  updateDetails = async () => {
    this.setState({
      saveDetailsFalse: true,
    });
    const {
      richtTextBiofield,
      agentTowns,
      Ziplist,
      serviceAreas,
      optionsSelected,
      referedByName,
      referedById,
      addUserName,
      lenderInfo,
      mlsSateList,
      mlsIdsUpdateStatus,
      citySearch,
      zipCode,
      addUserEmail,
      userDetails,
    } = this.state;

    const id = userDetails.id;

    let agentInfo = {
      agentName: addUserName,
      agentEmail: addUserEmail,
    };

    let data = {};
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values["address"] =
          citySearch.length > 0
            ? citySearch
            : userDetails.newdata && userDetails.newdata.companyAddress;
        values["postalCode"] = zipCode ? zipCode : "";

        data = Object.assign(
          {},
          {
            entityData: {
              newdata: {
                agentTowns: agentTowns,
                zipCodes: Ziplist ? Ziplist : "",
                whatsapp: values.whatsapp ? values.whatsapp : "",
                serviceAreas: serviceAreas ? serviceAreas : "",
                titleorPosition: values.titleorPosition
                  ? values.titleorPosition
                  : "",
                mobile: values.mobile ? values.mobile : "",
                language: values.language ? values.language : "",
                faxNumber: values.faxNumber ? values.faxNumber : "",
                Specialties: optionsSelected ? optionsSelected : "",
                TaxNumber: values.TaxNumber ? values.TaxNumber : "",
                companyName: values.companyName ? values.companyName : "",
                companyAddress: values.address ? values.address : "",
                officeZipCode: values.postalCode,
                website: values.website ? values.website : "",
                hobbies: values?.hobbies ? values?.hobbies : "",
                anniversary: values?.anniversary ? values?.anniversary : "",
                joiningDate: values?.joiningDate ? values?.joiningDate : "",
                dob: values?.dob ? values?.dob : "",
              },
              email: values.email ? values.email : "",
              firstName: values.firstName ? values.firstName : "",
              lastName: values.lastName ? values.lastName : "",
              mlsId: values.mlsId ? values.mlsId.toUpperCase() : "",
              phone: values.phone ? values.phone : "",
              profilePicture: values.profilePicture
                ? values.profilePicture
                : "",
              profileUrl: values.profileUrl ? values.profileUrl : "",
              userBio: richtTextBiofield ? richtTextBiofield : "",
              referedByName: referedByName,
              referedById: referedById,
              licenseIds: values.licenseIds ? values.licenseIds : "",
              agentInfo: agentInfo,
              addUserName: addUserName,
              lenderInfo: lenderInfo,
              mlsSateList: mlsSateList,
              mlsIdsUpdateStatus: mlsIdsUpdateStatus,
              isActive: true,
              showAllZipCodes: this.state.allZipCodes
            },
          }
        );

        if (!this.state.updateAgentPortfolio) {
          data.entityData["portfolioName"] = ["My portfolio"];
        }
      }
    });
    if (data.entityData.email && data.entityData.firstName) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        this.setState({
          editing: false,
          saveDetailsFalse: false,
        });
        notification["success"]({
          message: "Your profile has been updated",
          // description: "Your profile details updated successfully",
          duration: 3,
        });
      }
    }
  };

  handleZipCode = (address) => {
    let companyAddress = address.label.split(",");
    const citySearch = companyAddress;
    this.setState({ citySearch });
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "postal_code") {
            this.setState({
              zipCode: address[i].long_name,
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleServiceAreas = (address) => {
    let citiesList =
      this.state.serviceAreas.length > 0 ? this.state.serviceAreas : [];
    let stateName = "";
    let cityValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateName = address[i].short_name;
          }
        }
        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateName.toLowerCase() ||
            restState.stateType.toLowerCase() === stateName.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateName,
            type: "stateName",
          };
          if (newSearchLocation) {
            citiesList.push(newSearchLocation);
          }
          this.setState({
            serviceAreas: citiesList,
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  onReferedBySelect = (value, option) => {
    this.setState({
      referedById: option.props.value,
      referedByName: option.props.children,
    });
  };

  onReferedBySelectLender = (value, option) => {
    this.setState({
      lenderInfo: value,
    });
  };

  onReferedBySelectAgent = (value, option) => {
    this.setState({
      addUserEmail: option.email,
      addUserName: option.label,
    });
  };

  onSelect = (result) => {
    const address = result.label.split(",");
    let vara = this.state.cities.cities;
    vara.push(address[0]);
    this.setState({
      cities: {
        cities: vara,
        type: "cities",
      },
    });
  };
  onClose = (value) => {
    const data =
      this.state.serviceAreas &&
      this.state.serviceAreas.filter((city) => city !== value);
    this.setState({
      serviceAreas: data,
    });
  };

  onCloseMlsSate = (value) => {
    const data =
      this.state.mlsSateList &&
      this.state.mlsSateList.filter((id) => id !== value);
    this.setState({
      mlsSateList: data,
    });
  };

  handleChange = (value) => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    } else {
      this.handleZipTowns(this.state.zip);
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };

  handleZipTowns = (address) => {
    try {
      geocodeByAddress(address)
        .then((results) => {
          let address = results[0].address_components;

          let agentTownArray =
            this.state.agentTowns.length > 0 ? this.state.agentTowns : [];
          let townInfo = {
            cityName: "",
            stateName: "",
            zipCode: "",
          };

          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              townInfo["cityName"] = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              townInfo["stateName"] = address[i].short_name;
            }
            if (address[i].types[0] === "postal_code") {
              townInfo["zipCode"] = address[i].long_name;
            }
          }
          agentTownArray.push(townInfo);
          this.setState({
            agentTowns: agentTownArray,
          });
        })
        .catch((error) => console.error("Error", error));
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleDeselect = (value) => {
    this.handleDeselectZipTowns(value);
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handleDeselectZipTowns = (value) => {
    let tempAgentTowns = this.state.agentTowns;
    let result = tempAgentTowns.filter(
      (agentTownInfo) => !agentTownInfo.zipCode.includes(value)
    );
    this.setState({
      agentTowns: result,
    });
  };
  getZiplist = (agentTowns, Ziplist) => {
    this.setState({
      agentTowns,
      Ziplist,
    });
  };
  handlespecialties = (value) => {
    this.setState({ optionsSelected: value });
    // if (
    //   value.length === this.props &&
    //   this.props.userDetails &&
    //   this.props.userDetails.subscriptionPack &&
    //   this.props.userDetails.subscriptionPack.specialites &&
    //   this.props.userDetails.subscriptionPack.specialites
    // ) {
    //   notification.info({
    //     message: "Please upgrade to add more specialites",
    //   });
    // }
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      showSubscriptions: true,
    });
  };

  setLoadingfalse = () => {
    this.setState({
      dataLoadingFalse: false,
    });
  };
  handleAddUser = () => {
    const { addUserName, addUserEmail } = this.state;
    if (addUserName !== "" && addUserEmail !== "") {
      let agentsList = this.state.agentsList;
      agentsList.push({
        label: addUserName,
        email: addUserEmail,
        value: addUserName,
      });
      this.setState({
        agentsList,
      });
    }
    this.setState({
      userInput: true,
    });
  };

  handleAddMlsSate = () => {
    const { mlsState, mlsStateId } = this.state;
    let mlsSateList = [];
    let checkData;
    this.state.mlsSateList &&
      this.state.mlsSateList.map((id) => {
        if (mlsStateId === id.id && mlsState === id.state) {
          checkData = true;
        }
        return true;
      });

    if (checkData) {
      notification["error"]({
        message:
          "You have already added " +
          mlsState +
          " state and " +
          mlsStateId +
          " zip code. Please add another",
      });
      this.setState({
        mlsStateId: "",
      });
    } else if ((mlsState && mlsState.length === 0) || mlsStateId === "") {
      notification["error"]({
        message: "Please input mlsState and mlsId",
      });
    } else {
      mlsSateList.push(...this.state.mlsSateList, {
        id: mlsStateId,
        state: mlsState,
      });
      this.setState({
        mlsSateList,
        mlsStateId: "",
        mlsState: "",
        mlsIdsUpdateStatus: true,
      });
    }
  };

  handleMlsId = (e) => {
    this.setState({
      mlsStateId: e.target.value,
    });
  };

  handleMlsSate = (value) => {
    this.setState({
      mlsState: value,
    });
  };

  handleaddUserName = (e) => {
    this.setState({
      addUserName: e.target.value,
    });
  };

  handleaddUserEmail = (e) => {
    this.setState({
      addUserEmail: e.target.value,
    });
  };

  handleAgentPortFolio = (agentPortfolio) => {
    this.setState({
      agentPortfolio,
    });
  };
  handleShowAllZips=(e)=>{
    this.setState({
      
    });
    this.setState({
      allZipCodes:e.target.checked,
      agentTowns:[],
      Ziplist:[]
    })
  }
  render() {
    const {
      saveDetailsFalse,
      serviceAreas,
      userDetails,
      mlsStateId,
      mlsState,
      allUsersList,
      allZipCodes
    } = this.state;
    const opts = [
      { label: "Buyer representation", value: "Buyer representation" },
      { label: "Seller representation", value: "Seller representation" },
      { label: "Downsizing", value: "Downsizing" },
      { label: "Upsizing", value: "Upsizing" },
      { label: "Relocation", value: "Relocation" },
      { label: "Investment properties", value: "Investment properties" },
      { label: "Flipping", value: "Flipping" },
      { label: "1031 exchanges", value: "1031 exchanges" },
      { label: "Commercial", value: "Commercial" },
      { label: "Land", value: "Land" },
      { label: "New Construction", value: "New Construction" },
      { label: "Builder representation", value: "Builder representation" },
      { label: "waterfront properties", value: "waterfront properties" },
      { label: "Farm/horse properties", value: "Farm/horse properties" },
      { label: "Rentals", value: "Rentals" },
      { label: "First Time Buyers", value: "First Time Buyers" },
      { label: "Short Sales", value: "Short Sales" },
      { label: "Business Representation", value: "Business Representation" },
      { label: "Over 55/Active Adult", value: "Over 55/Active Adult" },
      { label: "Bank Owned", value: "Bank Owned" },
    ];

    // const mlsStates = [
    //   { label: "CT", value: "CT" },
    //   { label: "RI", value: "RI" },
    //   { label: "NH", value: "NH" },
    //   { label: "MA", value: "MA" },
    // ];
    const mlsStates = [
      { label: "Alabama", value: "AL" },
      { label: "Alaska", value: "AK" },
      { label: "Arizona", value: "AZ" },
      { label: "Arkansas", value: "AR" },
      { label: "California", value: "CA" },
      { label: "Colorado", value: "CO" },
      { label: "Connecticut", value: "CT" },
      { label: "Delaware", value: "DE" },
      { label: "Florida", value: "FL" },
      { label: "Georgia", value: "GA" },
      { label: "Hawaii", value: "HI" },
      { label: "Idaho", value: "ID" },
      { label: "Illinois", value: "IL" },
      { label: "Indiana", value: "IN" },
      { label: "Iowa", value: "IA" },
      { label: "Kansas", value: "KS" },
      { label: "Kentucky", value: "KY" },
      { label: "Louisiana", value: "LA" },
      { label: "Maine", value: "ME" },
      { label: "Maryland", value: "MD" },
      { label: "Massachusetts", value: "MA" },
      { label: "Michigan", value: "MI" },
      { label: "Minnesota", value: "MN" },
      { label: "Mississippi", value: "MS" },
      { label: "Missouri", value: "MO" },
      { label: "Montana", value: "MT" },
      { label: "Nebraska", value: "NE" },
      { label: "Nevada", value: "NV" },
      { label: "New Hampshire", value: "NH" },
      { label: "New Jersey", value: "NJ" },
      { label: "New Mexico", value: "NM" },
      { label: "New York", value: "NY" },
      { label: "North Carolina", value: "NC" },
      { label: "North Dakota", value: "ND" },
      { label: "Ohio", value: "OH" },
      { label: "Oklahoma", value: "OK" },
      { label: "Oregon", value: "OR" },
      { label: "Pennsylvania", value: "PA" },
      { label: "Rhode Island", value: "RI" },
      { label: "South Carolina", value: "SC" },
      { label: "South Dakota", value: "SD" },
      { label: "Tennessee", value: "TN" },
      { label: "Texas", value: "TX" },
      { label: "Utah", value: "UT" },
      { label: "Vermont", value: "VT" },
      { label: "Virginia", value: "VA" },
      { label: "Washington", value: "WA" },
      { label: "West Virginia", value: "WV" },
      { label: "Wisconsin", value: "WI" },
      { label: "Wyoming", value: "WY" },
    ];
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="agent_profile_form">
        {"email" in userDetails && (
          <Form
            initialValues={{ remember: true }}
            onSubmit={this.handleSubmit}
            size="large"
          >
            <Spin spinning={saveDetailsFalse}>
              <Row>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">First Name</div>
                  <Form.Item>
                    {getFieldDecorator("firstName", {
                      initialValue: `${
                        userDetails?.firstName ? userDetails?.firstName : ""
                      }`,
                    })(
                      <Input placeholder="First Name" className="form-input" />
                    )}
                  </Form.Item>

                  <div className="label-titles">Phone</div>
                  <Form.Item name="phone">
                    {getFieldDecorator("phone", {
                      initialValue: `${
                        userDetails?.phone ? userDetails?.phone : ""
                      }`,
                    })(
                      <InputNumber
                        placeholder="Enter your phone number"
                        className="form-input-number"
                        formatter={(value) => {
                          const cleaned = `${value}`.replace(/\D/g, "");
                          const match = cleaned.match(
                            /^(1|)?(\d{3})(\d{3})(\d{4})$/
                          );
                          if (match) {
                            const intlCode = match[1] ? "+1 " : "";
                            return [
                              intlCode,
                              "(",
                              match[2],
                              ") ",
                              match[3],
                              "-",
                              match[4],
                            ].join("");
                          }
                          return null;
                        }}
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Company Name</div>
                  <Form.Item name="companyName">
                    {getFieldDecorator("companyName", {
                      initialValue: `${
                        userDetails
                          ? userDetails.newdata
                            ? userDetails.newdata.companyName &&
                              userDetails.newdata.companyName
                            : ""
                          : ""
                      }`,
                    })(
                      <Input
                        placeholder="Enter your company name"
                        className="form-input"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Mobile</div>
                  <Form.Item name="mobile">
                    {getFieldDecorator("mobile", {
                      initialValue: `${
                        userDetails?.newdata
                          ? userDetails.newdata.mobile &&
                            userDetails.newdata.mobile
                          : ""
                      }`,
                    })(
                      <InputNumber
                        className="form-input-number"
                        placeholder="mobile"
                        formatter={(value) => {
                          const cleaned = `${value}`.replace(/\D/g, "");
                          const match = cleaned.match(
                            /^(1|)?(\d{3})(\d{3})(\d{4})$/
                          );
                          if (match) {
                            const intlCode = match[1] ? "+1 " : "";
                            return [
                              intlCode,
                              "(",
                              match[2],
                              ") ",
                              match[3],
                              "-",
                              match[4],
                            ].join("");
                          }
                          return null;
                        }}
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Joining Date</div>
                  <Form.Item name="mobile">
                    {getFieldDecorator("joiningDate", {
                      initialValue: moment(userDetails?.newdata?.joiningDate),
                      // initialValue: `${
                      //   userDetails?.newdata
                      //     ? userDetails.newdata.joiningDate &&
                      //       userDetails.newdata.joiningDate
                      //     : ""
                      // }`,
                    })(
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        style={{ width: "100%", height: "40px" }}
                        className="date_admin"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Date of Birth</div>
                  <Form.Item name="mobile">
                    {getFieldDecorator("dob", {
                      initialValue: moment(userDetails?.newdata?.dob),
                      // initialValue: `${
                      //   userDetails?.newdata
                      //     ? userDetails.newdata.joiningDate &&
                      //       userDetails.newdata.joiningDate
                      //     : ""
                      // }`,
                    })(
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        style={{ width: "100%", height: "40px" }}
                        className="date_admin"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Title or Position</div>
                  <Form.Item name="titleorPosition">
                    {getFieldDecorator("titleorPosition", {
                      initialValue: `${
                        userDetails?.newdata
                          ? userDetails?.newdata?.titleorPosition &&
                            userDetails?.newdata?.titleorPosition
                          : ""
                      }`,
                    })(
                      <Input
                        placeholder="Enter your title or position"
                        className="form-input"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Fax Number</div>
                  <Form.Item name="faxNumber">
                    {getFieldDecorator("faxNumber", {
                      initialValue: `${
                        userDetails
                          ? userDetails.newdata
                            ? userDetails.newdata.faxNumber
                            : ""
                          : ""
                      }`,
                    })(
                      <InputNumber
                        placeholder="Enter your Fax number"
                        className="form-input-number"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">
                    Languages (Separate with commas)
                  </div>
                  <Form.Item name="language">
                    {getFieldDecorator("language", {
                      initialValue: `${
                        userDetails
                          ? userDetails.newdata
                            ? userDetails.newdata.language
                            : ""
                          : ""
                      }`,
                    })(
                      <Input
                        placeholder="English,Spanish,French"
                        className="form-input"
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">Last Name</div>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Full Name!",
                      },
                    ]}
                  >
                    {getFieldDecorator("lastName", {
                      initialValue: `${
                        userDetails
                          ? userDetails.lastName
                            ? userDetails.lastName
                            : ""
                          : ""
                      }`,
                    })(
                      <Input
                        placeholder="Last Name"
                        value=""
                        className="form-input"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Email</div>
                  <Form.Item name="email">
                    {getFieldDecorator("email", {
                      initialValue: `${
                        userDetails
                          ? userDetails.email
                            ? userDetails.email
                            : ""
                          : ""
                      }`,
                    })(
                      <Input
                        readOnly={true}
                        placeholder="email"
                        className="form-input"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Company Address</div>
                  <Form.Item name="address" className="google-form-search">
                    <GooglePlacesAutocomplete
                      className="form-input"
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        // types: ["(cities)"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        defaultInputValue: `${
                          userDetails && userDetails.newdata
                            ? userDetails.newdata.companyAddress
                            : ""
                        }`,
                        placeholder: "Type Company Address",
                        onChange: (result) => {
                          this.handleZipCode(result);
                        },
                      }}
                    />
                  </Form.Item>

                  <div className="label-titles">Website</div>
                  <Form.Item name="website">
                    {" "}
                    {getFieldDecorator("website", {
                      initialValue: `${
                        userDetails
                          ? userDetails.newdata && userDetails.newdata.website
                            ? userDetails.newdata.website
                            : ""
                          : ""
                      }`,
                    })(<Input placeholder="website" className="form-input" />)}
                  </Form.Item>

                  <div className="label-titles">Anniversary</div>
                  <Form.Item name="mobile">
                    {getFieldDecorator("anniversary", {
                      initialValue: moment(userDetails?.newdata?.anniversary),
                      // initialValue: `${
                      //   userDetails?.newdata
                      //     ? userDetails.newdata.joiningDate &&
                      //       userDetails.newdata.joiningDate
                      //     : ""
                      // }`,
                    })(
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        style={{ width: "100%", height: "40px" }}
                        className="date_admin"
                      />
                    )}
                  </Form.Item>
                  <div className="label-titles">Hobbies</div>
                  <Form.Item name="hobbies">
                    {getFieldDecorator("hobbies", {
                      initialValue: `${
                        userDetails?.newdata
                          ? userDetails.newdata.hobbies &&
                            userDetails.newdata.hobbies
                          : ""
                      }`,
                    })(<Input placeholder="Hobbies" className="form-input" />)}
                  </Form.Item>
                  <div className="label-titles">WhatsApp</div>
                  <Form.Item name="whatsapp">
                    {getFieldDecorator("whatsapp", {
                      initialValue: `${
                        userDetails
                          ? userDetails.newdata && userDetails.newdata.whatsapp
                            ? userDetails.newdata.whatsapp
                            : ""
                          : ""
                      }`,
                    })(
                      <InputNumber
                        placeholder="Enter whatsApp number with country code"
                        className="form-input-number"
                        formatter={(value) => {
                          const cleaned = `${value}`.replace(/\D/g, "");
                          const match = cleaned.match(
                            /^(1|)?(\d{3})(\d{3})(\d{4})$/
                          );
                          if (match) {
                            const intlCode = match[1] ? "+1 " : "";
                            return [
                              intlCode,
                              "(",
                              match[2],
                              ") ",
                              match[3],
                              "-",
                              match[4],
                            ].join("");
                          }
                          return null;
                        }}
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">
                    License IDs (if multiple, separate with commas)
                  </div>
                  <Form.Item name="licenseIds">
                    {getFieldDecorator("licenseIds", {
                      initialValue: `${
                        userDetails
                          ? userDetails.licenseIds
                            ? userDetails.licenseIds
                            : ""
                          : ""
                      }`,
                    })(
                      <Input
                        placeholder="License IDs"
                        className="form-input"
                        // placeholder="Add state-lic# example 'MA-9517648, CT-9492229'"
                      />
                    )}
                  </Form.Item>

                  <div className="label-titles">Lenders list</div>
                  <Form.Item name="lenderslist">
                    {this.state.lendersList.length > 0 &&
                      this.state.loadSelect && (
                        <Select
                          className="select_height"
                          mode="multiple"
                          showSearch
                          optionFilterProp="children"
                          // options={this.state.lendersList}
                          onChange={this.onReferedBySelectLender}
                          // onDeselect={() =>
                          //   this.onReferedByDeSelectLender(
                          //     this.state.lenderInfo
                          //   )
                          // }
                          placeholder="lenders list"
                          defaultValue={this.state.lenderInfo}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.lendersList.map((item) => (
                            <Option
                              disabled={
                                this.state.lenderInfo &&
                                this.state.lenderInfo.length === 2
                                  ? this.state.lenderInfo.includes(item.email)
                                    ? false
                                    : true
                                  : false
                              }
                              key={item.email}
                            >
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col
                      xl={{ span: 11, offset: 1 }}
                      lg={{ span: 11, offset: 1 }}
                      md={{ span: 11, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div className="label-titles">Add your state</div>

                      <Form.Item name="mlsState">
                        <Select
                          options={mlsStates}
                          optionFilterProp="children"
                          onChange={this.handleMlsSate}
                          placeholder="Add your state"
                          allowClear
                          value={mlsState}
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {mlsStates.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xl={{ span: 11, offset: 1 }}
                      lg={{ span: 11, offset: 1 }}
                      md={{ span: 11, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div className="label-titles">MLS id</div>

                      <Form.Item name="mlsStateId">
                        <Input
                          placeholder="MLS id"
                          style={{ textTransform: "uppercase" }}
                          className="form-input input-width"
                          onChange={this.handleMlsId}
                          value={mlsStateId}
                        />

                        <Button
                          type="primary"
                          onClick={this.handleAddMlsSate}
                          className="add-user-width"
                          icon={<PlusOutlined />}
                        >
                          Add MLS Id
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "-15px", marginBottom: "10px" }}
                >
                  {this.state.mlsSateList &&
                    this.state.mlsSateList.length > 0 &&
                    this.state.mlsSateList.map((id, state) => (
                      <div
                        style={{
                          display: "inline-flex",
                          margin: "0.73vw",
                          padding: "0 7px",
                          flexWrap: "wrap",
                          justifyContent: "space-around",
                          border: "1px solid darkgrey",
                          marginLeft: "0px",
                        }}
                      >
                        <div style={{ textTransform: "uppercase" }}>
                          {id.state + " - " + id.id}
                        </div>
                        <div
                          style={{
                            color: "grey",
                            cursor: "pointer",
                            marginLeft: "0.4vw",
                          }}
                          onClick={() => this.onCloseMlsSate(id)}
                        >
                          x
                        </div>
                      </div>
                    ))}
                </Col>

                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // style={{ marginTop: "-15px", marginBottom: "10px" }}
                >
                  <div className="label-titles">Referred by</div>
                  <Form.Item name="referedBy">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={allUsersList}
                      onSelect={this.onReferedBySelect}
                      placeholder="Referred by"
                      defaultValue={
                        userDetails &&
                        userDetails.referedByName &&
                        userDetails.referedByName !== ""
                          ? userDetails.referedByName
                          : ""
                      }
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {allUsersList.map((item) => (
                        <Select.Option key={item} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">
                    List 3-4 towns or counties you consider yourself to be an
                    expert in
                  </div>

                  <Form.Item
                    name="serviceAreas"
                    className="google-form-search-service-area"
                  >
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        types: ["(cities)"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      selectProps={{
                        value: "",
                        placeholder:
                          userDetails && userDetails.userProfile !== "B"
                            ? "Enter your service areas"
                            : "Enter your interseted town",
                        onChange: (result) => {
                          this.handleServiceAreas(result);
                        },
                      }}
                    ></GooglePlacesAutocomplete>
                    {serviceAreas &&
                      serviceAreas.map((city, index) => (
                        <>
                          {typeof city === "object" && (
                            <div
                              key={index}
                              style={{
                                display: "inline-flex",
                                margin: "0.73vw",
                                padding: "0 7px",
                                flexWrap: "wrap",
                                justifyContent: "space-around",
                                border: "1px solid darkgrey",
                                marginLeft: "0px",
                              }}
                            >
                              {city.cityName && (
                                <>
                                  <div>
                                    {city.cityName}, {city.stateName}
                                  </div>
                                  <div
                                    style={{
                                      color: "grey",
                                      cursor: "pointer",
                                      marginLeft: "0.4vw",
                                    }}
                                    onClick={() => this.onClose(city)}
                                  >
                                    x
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 23, offset: 1 }}
                  lg={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="label-titles">
                    
                    <span>Zip codes serviced</span> &nbsp; &nbsp;
                    <Checkbox
                        onChange={(e)=>this.handleShowAllZips(e,"zip")}
                        checked={allZipCodes}
                      >
                        {" "}
                       Show In All Zip Codes{" "}
                      </Checkbox>
                    </div>
                  <AddZipCodes
                    handleTotalZipList={this.getZiplist}
                    oldZiplist={this.state.Ziplist}
                    allZipCodes={allZipCodes}
                  />
                </Col>

                {userDetails && userDetails.userProfile === "AG" && (
                  <>
                    <Col
                      xl={{ span: 23, offset: 1 }}
                      lg={{ span: 23, offset: 1 }}
                      md={{ span: 23, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div className="label-titles">
                        Specialties
                        {/* {userDetails &&
                          userDetails.subscriptionPack &&
                          userDetails.subscriptionPack.specialites && (
                            <p
                              style={{
                                float: "right",
                                marginBottom: "0px",
                                color: "red",
                              }}
                            >
                              {userDetails.subscriptionPack.specialites -
                                optionsSelected.length}{" "}
                              Specialties left
                            </p>
                          )} */}
                      </div>
                      {/* {userDetails &&
                        userDetails.subscriptionPack &&
                        userDetails.subscriptionPack.specialites && ( */}
                      <Form.Item name="specialties">
                        <Select
                          mode="multiple"
                          style={{
                            width:
                              // optionsSelected.length >
                              // userDetails.subscriptionPack.specialites - 1
                              //   ? window.screen.width >= "1199"
                              //     ? "75%"
                              //     : window.screen.width >= "768" &&
                              //       window.screen.width < "1198"
                              //     ? "70%"
                              //     : "60%"
                              //   :
                              "100%",
                          }}
                          placeholder="Please select"
                          onChange={this.handlespecialties}
                          // defaultValue={
                          //   this.state.optionsSelected
                          // }
                          value={this.state.optionsSelected}
                          className="form-input-profile1"
                          showSearch={
                            window.screen.width >= "992" ? true : false
                          }
                          size="large"
                        >
                          {opts?.map(
                            (item) => (
                              // userDetails &&
                              // userDetails.subscriptionPack &&
                              // userDetails.subscriptionPack.specialites && (
                              <Option
                                // disabled={
                                //   optionsSelected &&
                                //   optionsSelected.length >
                                //     userDetails.subscriptionPack.specialites - 1
                                //     ? optionsSelected.includes(item.value)
                                //       ? false
                                //       : true
                                //     : false
                                // }
                                key={item.value}
                              >
                                {item.value}
                              </Option>
                            )
                            // )
                          )}
                        </Select>
                        {/* {optionsSelected.length >
                          userDetails.subscriptionPack.specialites - 1 && ( 
                         <Button
                          type="default"
                          onClick={this.handleLink}
                          icon={<PlusOutlined />}
                          className="upgrade-add-btn-sub"
                          style={{
                            background: "red",
                            color: "white",
                            borderColor: "red",
                          }}
                        >
                          <span
                            className="upgrade-add-more-sub"
                            style={{ color: "white" }}
                          >
                            Upgrade to Add More
                          </span>
                        </Button> 
                         )}   */}
                      </Form.Item>
                      {/* )} */}
                      {/* {loadTotalData && (
                        <>
                          <div className="label-titles">Portfolio</div>
                          <Form.Item name="Portfolio">
                            <Select
                              style={{
                                width: "100%",
                              }}
                              placeholder="Please select"
                              onChange={this.handleAgentPortFolio}
                              className="form-input-profile1"
                              defaultValue={
                                oldCategories &&
                                oldCategories.map((Category) => Category.label)
                              }
                            >
                              {["Previous works"].map((province, index) => (
                                <Select.Option key={index} value={province}>
                                  {province}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </>
                      )} */}
                    </Col>
                  </>
                )}
                {userDetails &&
                  userDetails.userProfile !== "B" &&
                  userDetails.userProfile !== "S" && (
                    <Col
                      xl={{ span: 23, offset: 1 }}
                      lg={{ span: 23, offset: 1 }}
                      md={{ spana: 23, offset: 1 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <div id="About" className="label-titles">
                        About me{" "}
                      </div>
                      <Form.Item
                        name="userBio"
                        // initialValue={
                        //   userDetails
                        //     ? userDetails.userBio
                        //       ? userDetails.userBio
                        //       : ""
                        //     : ""
                        // }
                      >
                        <RicheText
                          handleRichtextBio={this.handleRichtextBio}
                          richtTextBiofieldProp={
                            userDetails.userBio ? userDetails.userBio : ""
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                <Col
                  xl={{ span: 8, offset: 1 }}
                  lg={{ span: 8, offset: 1 }}
                  md={{ span: 8, offset: 1 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="update-btn"
                  >
                    Update Profile
                  </Button>
                </Col>
              </Row>
            </Spin>
          </Form>
        )}
      </div>
    );
  }
}
export default Form.create({ name: "PersonalInfo" })(withRouter(PersonalInfo));
