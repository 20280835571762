import { Card, Col, Collapse, Icon, Pagination, Row, Spin } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { list } from "../api/Apis";
const { Panel } = Collapse;

export default class PropertiesVisitedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyStatastics: [],
      dataLoading: false,
      minValue: 0,
      maxValue: 10,
      currentPage: 1,
    };
  }
  componentDidMount() {
    this.getPropertyStats();
  }

  getPropertyStats = () => {
    this.setState({
      dataLoading: true,
    });
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let reqQuery = {
      orgId,
      isEmailPropertyVisited: true,
    };
    list("getPropertyStats", reqQuery).then((response) => {
      response.data.map((each, index) => (each["key"] = index + 1));
      this.setState({
        propertyStatastics: response.data,
        dataLoading: false,
      });
    });
  };

  handlePagination = async (page, pageSize, load) => {
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
    });
  };

  showPropertyStats = (property) => {
    return (
      <div>
        <Row style={{ display: "flex" }}>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              marginLeft: "40px",
            }}
          >
            Property Link :{" "}
            <a
              href={property.propertyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {property.propertyLink}
            </a>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Views :
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              {" "}
              &nbsp; {property.viewCount}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            total duration spent :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.totalTimeSpentOnProperty?.Hours} Hours{" "}
              {property.totalTimeSpentOnProperty?.Min} min{" "}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Last visited :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {moment.unix(property.lastVisited).format("MM/DD/YYYY hh:mm A")}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Question asked :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.questionAsked}
            </p>
          </Col>
          <Col
            style={{
              color: "#aaa",
              fontSize: "14px",
              marginRight: "1vw",
              display: "flex",
            }}
          >
            Sheduled Time :{" "}
            <p style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.85)" }}>
              &nbsp;
              {property.setSheduledTime}
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { propertyStatastics, minValue, maxValue, currentPage, dataLoading } =
      this.state;
    const { emailClicksPorp } = this.props;
    return (
      <div>
        {window.location.pathname !== "/application/PropertiesVisited" ? (
          <Card
            className="mob-card"
            bodyStyle={{
              padding: "14px",
              cursor: "pointer",
            }}
          >
            <Link to="/application/PropertiesVisited">
              <Row justify="space-around">
                <Col
                  xxl={{ span: 7 }}
                  xl={{ span: 7 }}
                  lg={{ span: 24 }}
                  xs={{ span: 8 }}
                  sm={{ span: 8 }}
                  md={{ span: 8 }}
                  style={{
                    borderRadius: "5px",
                    padding: "17px",
                  }}
                >
                  <i
                    class="fa fa-eye dashboard-icons"
                   
                  ></i>
                </Col>
                <Col
                  xxl={{ span: 17 }}
                  xl={{ span: 17 }}
                  lg={{ span: 24 }}
                  xs={{ span: 8 }}
                  sm={{ span: 8 }}
                  md={{ span: 8 }}
                >
                  <div>
                    <h4 className="mid-title">
                      Number of Properties Visited From Emails
                    </h4>
                    <h1 className="result-small">{emailClicksPorp}</h1>
                  </div>
                </Col>
              </Row>
            </Link>
          </Card>
        ) : (
          <Spin spinning={dataLoading}>
            <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
              <div
                onClick={() => this.props.history.goBack()}
                style={{
                  backgroundColor: "#f0f2f5",
                  cursor: "pointer",
                  paddingTop: "8px",
                  paddingLeft: "25px",
                  fontWeight: "600",
                }}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  <Icon type="left" />
                  Back To Dashboard
                </span>
              </div>
              <Col span={24} style={{ padding: "24px" }}>
                <Card
                  // className="mob-card"
                  className="ant-card-hoverable home-card-space-bw"
                  style={{ borderRadius: "5px" }}
                  bordered={false}
                >
                  <Row style={{ display: "block" }}>
                    <h3 style={{ textTransform: "capitalize" }}>
                      Properties visited
                    </h3>
                    {propertyStatastics.length > 0 ? (
                      propertyStatastics
                        .slice(minValue, maxValue)
                        .map((eachStats, index) => (
                          <>
                            <Collapse
                              defaultActiveKey={"1"}
                              // onChange={this.handleCollapseActive}
                              key={eachStats.key}
                            >
                              <Panel
                                header={eachStats.propertyTitle}
                                key={eachStats.key}
                              >
                                {this.showPropertyStats(eachStats)}
                              </Panel>
                            </Collapse>
                          </>
                        ))
                    ) : (
                      <>
                        <h4>NO RECORD FOUND</h4>
                      </>
                    )}
                    <Pagination
                      defaultPageSize={10}
                      // showSizeChanger=true,
                      // pageSizeOptions={["20", "50", "100"]}
                      total={propertyStatastics.length}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      current={currentPage}
                      onChange={this.handlePagination}

                      // onShowSizeChange=handleToSizeChange,
                      // itemRender=itemRender
                    />
                  </Row>
                </Card>
              </Col>
            </Row>
          </Spin>
        )}
      </div>
    );
  }
}
