import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Form, Input, Card, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import { add, update } from '../../api/Apis';
import AppConfigs from '../../Config/AppConfig';

const { Option } = Select;

class SubscriptionOperations extends Component {
    state = {
        userDetails: {},
        titels: { "AG": "Show on agent search page", "L": "Show on lenders page ", "V": "Show up on marketplace" },
        editablePlanData: {},
        showUpdateButton:false
    };
    componentDidMount() {
        this.setEditPlaneData()
    }
    setEditPlaneData = async () => {
        const editablePlanData = this.props.history.location.state
        if(editablePlanData){
            await this.setState({
                editablePlanData,
                showUpdateButton:true
            })
        }
       
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let orgId = JSON.parse(localStorage.getItem("orgId"))

        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                this.setState({
                    loading: true
                })
                let data = {
                    "entityData": {
                        "userType": values["userType"],
                        "planTitle": values["name"],
                        "zipcodes": values["zipcodes"],
                        "specialites": values["userType"] === "V" ? 0 : values["specialites"],
                        "showOnAgentSearchPage": values["showOnAgentSearchPage"],
                        "showOnListAgentPage": values["userType"] === "V" ? "N" : values["showOnListAgentPage"],
                        "bannerAd": values["bannerAd"] === "L" ? values["bannerAd"] : "N",
                        "premierStatus": values["premierStatus"],
                        "price": values["price"],
                        "recordStatus": true,
                        "isActive": false,
                        orgId
                    }
                }
                add(AppConfigs["entityNames"]["subscriptionPacks"], data)
                    .then(result => {
                        if (result.success) {
                            message.success(`Subscription created successfully.`);
                            this.props.history.push('/application/subscriptions');
                        } else {
                            console.log(result.errors);
                        }
                    });
            }
        });
    }
    handleToupdate =(e,userId)=>{
        e.preventDefault();
        let orgId = JSON.parse(localStorage.getItem("orgId"))

        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                this.setState({
                    loading: true
                })
                let data = {
                    "entityData": {
                        "userType": values["userType"],
                        "planTitle": values["name"],
                        "zipcodes": values["zipcodes"],
                        "specialites": values["userType"] === "V" ? 0 : values["specialites"],
                        "showOnAgentSearchPage": values["showOnAgentSearchPage"],
                        "showOnListAgentPage": values["userType"] === "V" ? "N" : values["showOnListAgentPage"],
                        "bannerAd": values["bannerAd"] === "L" ? values["bannerAd"] : "N",
                        "premierStatus": values["premierStatus"],
                        "price": values["price"],
                        orgId
                    }
                }
                update(AppConfigs["entityNames"]["subscriptionPacks"],userId,data)
                    .then(result => {
                        if (result.success) {
                            message.success(`Subscription updated successfully.`);
                            this.props.history.push('/application/subscriptions');
                        } else {
                            console.log(result.errors);
                        }
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { editablePlanData,showUpdateButton } = this.state
        return (            
                <div style={{ marginTop: "5vw" }}>
                    <Row>
                        <Col
                            xxl={{ span: 8, offset: 8 }}
                            xl={{ span: 10, offset: 7 }}
                            lg={{ span: 12, offset: 6 }}
                        >
                            <Form onSubmit={this.handleSubmit}  >
                                <Card title="Add Subscription" style={{ borderRadius: '10px' }}>
                                    <Row gutter={10}>
                                        <Col lg={24} md={24} sm={24} sx={24}>
                                            <Form.Item className="Formitem"
                                                label={<span style={{ color: '#7B8190', fontSize: '14px' }}>User Type</span>} >
                                                {getFieldDecorator('userType', {
                                                    rules: [{ required: true, message: 'Please select value!' }],
                                                    initialValue: editablePlanData && editablePlanData.userType
                                                    //  this.props.history.location.state !== "undefined" &&
                                                    // this.props.history.location.state !== "" ? this.props.history.location.state.userType && this.props.history.location.state.userType : ""
                                                })(<Select>
                                                    <Option key="V" value="V">Vendor</Option>
                                                    <Option key="L" value="L">Lender</Option>
                                                </Select>)}
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} md={24} sm={24} sx={24}>
                                            <Form.Item className="Formitem"
                                                label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Name</span>}>
                                                {getFieldDecorator('name', {
                                                    rules: [{ required: true, message: 'Please input name!' }],
                                                    initialValue: editablePlanData && editablePlanData.planTitle
                                                    //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.name && this.props.history.location.state.name : ""

                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} md={24} sm={24} sx={24}>
                                            <Form.Item className="Formitem"
                                                label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Zipcodes</span>}>
                                                {getFieldDecorator('zipcodes', {
                                                    rules: [{ required: true, message: 'Please input no of zipcode!' }],
                                                    initialValue: editablePlanData && editablePlanData.zipcodes
                                                    //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.zipcodes && this.props.history.location.state.zipcodes : ""
                                                })(<Input type="number" />)}
                                            </Form.Item>
                                        </Col>
                                        {getFieldValue('userType') !== "V" &&
                                            <Col lg={24} md={24} sm={24} sx={24}>
                                                <Form.Item className="Formitem"
                                                    label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Specialites</span>}>
                                                    {getFieldDecorator('specialites', {
                                                        rules: [{ required: false, message: 'Please input no of specialites!' }],
                                                        initialValue: editablePlanData && editablePlanData.specialites
                                                        //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.specialites && this.props.history.location.state.specialites : ""
                                                    })(<Input type="number" />)}
                                                </Form.Item>
                                            </Col>}
                                        <Col lg={24} md={24} sm={24} sx={24}>
                                            <Form.Item className="Formitem"
                                                label={<span style={{ color: '#7B8190', fontSize: '14px' }}>{getFieldValue('userType') ? this.state.titels[getFieldValue('userType')] : "Show on agent search page"}</span>} >
                                                {getFieldDecorator('showOnAgentSearchPage', {
                                                    rules: [{ required: true, message: 'Please select value!' }],
                                                    initialValue: editablePlanData && editablePlanData.showOnAgentSearchPage
                                                    //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.showOnAgentSearchPage && this.props.history.location.state.showOnAgentSearchPage : ""
                                                })(<Select>
                                                    <Option key="N" value="N">No</Option>
                                                    <Option key="Y" value="Y">Yes</Option>
                                                    <Option key="P" value="P">Premier placement at top of town/zip searches</Option>
                                                </Select>)}
                                            </Form.Item>
                                        </Col>
                                        {getFieldValue('userType') === "AG" &&
                                            <Col lg={24} md={24} sm={24} sx={24}>
                                                <Form.Item className="Formitem"
                                                    label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Show on list agent page</span>} >
                                                    {getFieldDecorator('showOnListAgentPage', {
                                                        rules: [{ required: true, message: 'Please select value!' }],
                                                        initialValue: editablePlanData && editablePlanData.showOnListAgentPage
                                                        //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.showOnListAgentPage && this.props.history.location.state.showOnListAgentPage : ""
                                                    })(<Select>
                                                        <Option key="N" value="N">No</Option>
                                                        <Option key="Y" value="Y">Yes</Option>
                                                        <Option key="P" value="P">Premier placement at top of town/zip searches</Option>
                                                    </Select>)}
                                                </Form.Item>
                                            </Col>}
                                        {getFieldValue('userType') === "L" &&
                                            <Col lg={24} md={24} sm={24} sx={24}>
                                                <Form.Item className="Formitem"
                                                    label={<span style={{ color: '#7B8190', fontSize: '14px' }}>{"Banner Ad"}</span>} >
                                                    {getFieldDecorator('bannerAd', {
                                                        rules: [{ required: true, message: 'Please select value!' }],
                                                        initialValue: editablePlanData && editablePlanData.bannerAd
                                                        //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.bannerAd && this.props.history.location.state.bannerAd : ""
                                                    })(<Select>
                                                        <Option key="N" value="N">No</Option>
                                                        <Option key="Y" value="Y">Yes</Option>
                                                    </Select>)}
                                                </Form.Item>
                                            </Col>}
                                        <Col lg={24} md={24} sm={24} sx={24}>
                                            <Form.Item className="Formitem"
                                                label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Premier Status</span>} >
                                                {getFieldDecorator('premierStatus', {
                                                    rules: [{ required: true, message: 'Please select value!' }],
                                                    initialValue: editablePlanData && editablePlanData.premierStatus
                                                    //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.premierStatus && this.props.history.location.state.premierStatus : ""
                                                })(<Select>
                                                    <Option key="N" value="N">No</Option>
                                                    <Option key="Y" value="Y">Yes</Option>
                                                </Select>)}
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} md={24} sm={24} sx={24}>
                                            <Form.Item className="Formitem"
                                                label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Amount</span>}>
                                                {getFieldDecorator('price', {
                                                    rules: [{ required: true, message: 'Please input price!' }],
                                                    initialValue: editablePlanData && editablePlanData.price
                                                    //  this.props.history.location.state !== "undefined" && this.props.history.location.state !== "" ? this.props.history.location.state.price && this.props.history.location.state.price : ""
                                                })(<Input type="number" prefix={<i className="fa fa-usd" />} />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                <Row>
                                    <Form.Item style={{ marginTop: '5%' }}>
                                        <Button type="primary" style={{ backgroundColor: '#25559517', color: '#fff', marginRight: '30px', padding: '0 20px 0 20px', border: 'none' }}>
                                            <Link to="/application/subscriptions" style={{textDecoration:"none"}} >
                                                    Cancel
                                            </Link>
                                        </Button>

                                        {showUpdateButton ? <Button type="primary" htmlType="submit" loading={this.state.loading} onClick={(e)=>this.handleToupdate(e,editablePlanData.id)} style={{ float: 'right', backgroundColor: '#255595', color: 'white', padding: '0 20px 0 20px' }}   >
                                            Update
                                        </Button> :
                                            <Button type="primary" htmlType="submit" loading={this.state.loading} onClick={this.handleSubmit} style={{ float: 'right', backgroundColor: '#255595', color: 'white', padding: '0 20px 0 20px' }}   >
                                                Save
                                            </Button>}
                                    </Form.Item>
                                </Row>
                            </Form> 
                        </Col>
                    </Row>
                </div>
        );
    }
}
export default Form.create({ name: 'addSubscription' })(withRouter(SubscriptionOperations));
