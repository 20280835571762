import React, { Component } from "react";
import "antd/dist/antd.css";
import { Form, Icon, Input, Button, Row } from "antd";
import Amplify, { Auth } from "aws-amplify";
import awsConfigure from "../Config/AwsConfig";
import Logo from "../Images/poweredby.png";
import { getDetails, noAuthgetDetails } from "../api/Apis";
import AppConfigs from "../Config/AppConfig";
import jwtDecode from "jwt-decode";
import { CrossStorageClient } from 'cross-storage';

Amplify.configure(awsConfigure);

class Login extends Component {
  state = {
    errors: "",
    visible: false,
    loading: false,
    orgDetails: "",
  };

  componentDidMount = () => {
    this.getWebsiteData();
    this.handleAdminPanel();
  };
  handleAdminPanel = () => {
    let search = window.location.search;
    if (search !== "") {
      let params = new URLSearchParams(search);
      let token = params.get("jwtCode");
      if (token !== "") {
        var decoded = jwtDecode(token);

        const userDetails = Object.assign(
          {},
          {
            token,
            userid: decoded.sub,
          }
        );
        localStorage.setItem("AdminToken", JSON.stringify(token));

        this.getProfile(userDetails.userid);
      }
    }
  };
  getWebsiteData = () => {
    let websiteId = window.location.hostname;
    noAuthgetDetails("getWebsitesData", websiteId)
      .then((result) => {
        if (result.success) {
          this.setState({
            orgDetails: result.data.data,
          });
          localStorage.setItem("orgId", JSON.stringify(result.data.data.id));
          localStorage.setItem("orgDetails", JSON.stringify(result.data.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = (e) => {
    const { orgDetails } = this.state;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (
          values.username === orgDetails?.email &&
          orgDetails?.websiteName === window.location.hostname
        ) {
          this.doLogin();
        } else {
          console.log("else condition");
          this.doLogin();
        }
      }
    });
  };

  doLogin = async () => {
    try {
      this.setState({
        loading: true,
      });
      const user = await Auth.signIn(
        this.props.form.getFieldValue("username"),
        this.props.form.getFieldValue("password")
      );

      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      this.getProfile(userDetails.userid);
      localStorage.setItem("token", JSON.stringify(userDetails?.token));
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        var code = prompt("Please enter the code which is sent to your mail");
        if (code !== "") {
          await Auth.confirmSignUp(
            this.props.form.getFieldValue("username"),
            code
          ).then(async (result) => {
            const user = await Auth.signIn(
              this.props.form.getFieldValue("username"),
              this.props.form.getFieldValue("password")
            );
            const userDetails = Object.assign(
              {},
              {
                token: user.signInUserSession.idToken.jwtToken,
                userid: user.username,
              }
            );
            this.getProfile(userDetails.userid);
          });
        }
      } else if (err.code === "PasswordResetRequiredException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else if (err.code === "NotAuthorizedException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else if (err.code === "UserNotFoundException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else {
        this.setState({
          loading: false,
          errors: err.message,
        });
      }
    }
  };

  getWebsites = (websiteHost) => {
    noAuthgetDetails("getWebsitesData", websiteHost)
      .then((result) => {
        if (result.success) {
          let websiteThemes = result.data.data;
          this.setState({
            websiteThemes,
          });
          localStorage.setItem("orgId", JSON.stringify(result.data.data.id));
          localStorage.setItem("orgDetails",JSON.stringify(result.data.data))
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getProfile = async (id) => {
    this.setState({
      loading: true,
    });
    getDetails(AppConfigs["entityNames"]["users"], id).then(async (result) => {
      if (result.success) {
        // let orgId = JSON.parse(localStorage.getItem("orgId"));
        // if (result.data.id !== orgId) {
        //   this.setState({
        //     loading: false,
        //     errors: "Invalid user",
        //   });
        //   Auth.signOut().then((res) => console.log("Invalid user login"));
        //   return;
        // }
        this.getWebsites(window.location.hostname)
        if (
          result.data.userProfile === "S" &&
          window.location.hostname.indexOf("poweredbyagents") > -1
        ) {
          localStorage.setItem("userDetails", JSON.stringify(result.data));
          this.storeToken();
          this.props.history.push("/application/organization");
        } else if (
          (result.data.userProfile === "S" &&
            window.location.hostname.indexOf("poweredbyagents") === -1) ||
          (result.data.userProfile !== "S" && result.data.userProfile !== "A")
        ) {
          this.setState({
            loading: false,
            errors: "Invalid user",
          });
          Auth.signOut().then((res) => console.log("Invalid user login"));
          return;
        } else if (result.data.userProfile === "A") {
          // await this.getWebsiteData();
          let orgId = JSON.parse(localStorage.getItem("orgId"));
          if (result.data.orgId !== orgId) {
            this.setState({
              loading: false,
              errors: "Invalid user",
            });
            Auth.signOut().then((res) => console.log("Invalid user login"));
            return;
          } else {
            localStorage.setItem("userDetails", JSON.stringify(result.data));
            this.storeToken();
            this.props.history.push("/application/dashboard");
          }
        }
      } else {
        console.log(result.errors);
        this.setState({
          loading: false,
          errors: result.errors.toString(),
        });
      }
    });
  };

  storeToken = () => {
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    let website = orgDetails["websiteName"];
    var storage = new CrossStorageClient('https://'+website+'/hub.html');
    storage.onConnect().then(function() {
      return storage.set('token', localStorage.getItem("token"));
    })
    // .then(function() {
    //   return storage.get('existingKey', 'newKey');
    // }).then(function(res) {
    //   console.log(res.length); // 2
    // }).catch(function(err) {
    //   // Handle error
    // });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { orgDetails } = this.state;
    return (
      <div className="admin-login-form">
        <div className="login-fields">
          <Row>
            <div style={{ textAlign: "center" }}>
              {orgDetails ? (
                <img
                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgDetails?.Header.headerLogo}`}
                  alt="Logo"
                  style={{ width: "50%" }}
                />
              ) : (
                <img src={Logo} alt="Logo" style={{ width: "50%" }} />
              )}
            </div>
          </Row>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label={<label>Email</label>}>
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please input valid email" },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Username"
                />
              )}
            </Form.Item>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your Password!" },
                ],
              })(
                <Input.Password
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>

            {this.state.errors && (
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "#f5222d" }}>{this.state.errors}</span>
              </div>
            )}

            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
              className="login-form-button"
              style={{
                width: "100%",
                backgroundColor: "rgb(173 169 168)",
                borderColor: "rgb(173 169 168)",
                marginTop: "40px",
              }}
            >
              Log In
            </Button>
            <h4
              style={{
                textAlign: "center",
                marginTop: "5px",
                color: "black",
              }}
            >
              Version 5.10.25.06
            </h4>
          </Form>
        </div>
      </div>
    );
  }
}
export default Form.create({ name: "login" })(Login);
