import React, { Component } from "react";
import { Row, Col, Spin, Card, Upload, notification,Checkbox, Button } from "antd";
import ImageCrop from "antd-img-crop";
import "antd/es/modal/style";
import "antd/es/slider/style";
import { Storage } from "aws-amplify";
import profileAvatar from "../../Images/profileAvatar.png";

import { withRouter } from "react-router";
// import { withAppContext } from "../../../store/contextStore";
// import SocialMediaForm from "./SocialMediaForm";
import { update, getDetails } from "../../api/Apis";
import PersonalInfo from "./PersonalInfo";
import AgentSocialMediaDetails from "./AgentSocialMediaDetails";
import { ArrowLeftOutlined } from "@ant-design/icons";

class AgentProfileEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      imageLoading: false,
      image2Loading: false,
      profileImage: "",
      updateImage: true,
      portfoliosList: [],
      agentDetails: {},
      markAsManager: false,
    };
  }

  componentDidMount = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let agentDetails = await getDetails("users", this.props.match.params.id);

    // setTimeout(() => {
      if (agentDetails && agentDetails.data.profilePicture?.length) {
        this.setState({
          profileImage:
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            agentDetails.data.profilePicture[0],
          agentDetails: agentDetails.data,
          markAsManager:agentDetails.data.markAsManager,

        });
      }else{
        this.setState({
          agentDetails: agentDetails.data,
          markAsManager:agentDetails.data.markAsManager,

        })
      }
    // }, 3000);

  };

  savePic = async (data1, data2) => {
    const data = Object.assign(
      {},
      {
        entityData: {
          profilePicture: data2,
          profileUrl: data1,
          isActive:true
        },
      }
    );
    if (data.entityData.profilePicture && data.entityData.profileUrl) {
      const updatingUserDetails = await update(
        "users",
        this.props.match.params.id,
        data
      );
      if (updatingUserDetails.success) {
        this.setState({
          updateImage: false,
          imageLoading: false,
        });
        notification["success"]({
          message: "Your profile picture updated",
          duration: 3,
        });
      }
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleManagerBox = async (e,type) => {
    const { agentDetails } = this.state;
    const id = agentDetails.id;

    let data = {}
      this.setState({
        markAsManager: e.target.checked,
      });
      data = {
        entityData: {
          markAsManager: e.target.checked,
        },
      };

    update("users", id, data)
      .then(async (result) => {
        if (result.success && e.target.checked) {
          notification["success"]({
            message: "Your account updated",
            // description: "Your profile picture updated successfully",
            duration: 3,
            placement: "topLeft",
          });
        }
        const userProfileDetails = await getDetails("users", id);
        this.setState({
          agentDetails: userProfileDetails.data,
        });
      })
      .catch((error) => console.log(error));
  };

  handleCroppedImage = async (crop) => {
    this.setState({
      imageLoading: true,
    });
    const Images = [];
    let image = "";
    Storage.put(crop.uid, crop, {
      contentType: crop.type,
    }).then(async (result) => {
      Images.push(result.key);
      image =
        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
        result.key;
      if (image && Images) {
        this.setState({
          profileImage: image,
        });
        this.savePic(image, Images);
      }
    });
  };

  render() {
    const { fileList, profileImage, imageLoading, agentDetails, markAsManager } = this.state;
    return (
      <div>
          
        <Row>
        <Col
            xl={{ span: 23, offset: 1 }}
            lg={{ span: 23, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className="mobile_view_margin_Top"
            style={{ paddingTop: "30px" }}
          >
            <Button
              type="text"
              className="font-family-style font-medium-size"
              onClick={()=>this.props.history.goBack()}
              style={{background:"#f0f2f5",border:"#f0f2f5",color:"rgba(0, 0, 0, 0.65)"}}
            >
             <ArrowLeftOutlined />

              <span>Back to users </span>
              
            </Button>
          </Col>
          <Col span={22} offset={1} style={{ paddingTop: "30px" }}>
            <Card bodyStyle={{ padding: "0px" }}>
              <Row className="card-body-style">
                <Col
                  xl={{ span: 4, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  {agentDetails && agentDetails.userProfile === "AG" && (
                    <div style={{ marginBottom: "10px" }}>
                      <Checkbox
                        onChange={(e)=>this.handleManagerBox(e)}
                        checked={markAsManager}
                      >
                        {" "}
                        Mark as Manager{" "}
                      </Checkbox>
                    </div>
                  )}
                  <>
                    <h4> Profile image</h4>
                    <Spin
                      spinning={imageLoading}
                      size="large"
                      className="center-item"
                      style={{ marginTop: "10%" }}
                    >
                      <img
                        src={profileImage !== "" ? profileImage : profileAvatar}
                        alt="profile"
                        className="img-profile"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <div
                        className="update-profile-btn"
                        style={{
                          width: "100%",
                        }}
                      >
                        <ImageCrop
                          rotate={true}
                          onModalOk={this.handleCroppedImage}
                          modalOk="Save"
                        >
                          <Upload showUploadList={false}>
                            <span
                              className="Btn-name"
                              style={{ color: "#fff" }}
                            >
                              Update Profile
                            </span>
                          </Upload>
                        </ImageCrop>
                      </div>
                    </Spin>
                  </>
                </Col>
                <Col
                  xl={{ span: 17, offset: 1 }}
                  lg={{ span: 17, offset: 1 }}
                  md={{ span: 17, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  <PersonalInfo
                    fileList={fileList}
                    profileImage={profileImage}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: "2vw" }}>
          <Col span={22} offset={1}>
            <Card bodyStyle={{ padding: "0px" }}>
              <Row className="card-body-style">
                <Col
                  xl={{ span: 4, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  className="profile-titles"
                >
                  <span style={{ fontSize: "20px", fontWeight: 600 }}>
                    Social Media
                  </span>
                </Col>
                <Col
                  xl={{ span: 17, offset: 1 }}
                  lg={{ span: 17, offset: 1 }}
                  md={{ span: 17, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  <AgentSocialMediaDetails userDetails={agentDetails} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AgentProfileEdit);
