import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  Select,
  Popover,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { list, noAuthAdd } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";
import AwsConfig from "../../Config/AwsConfig";
import Amplify from "aws-amplify";
import NumberFormat from "react-number-format";

// import { formatPhoneNumber } from '../commonFunctions';
Amplify.configure(AwsConfig);
const { Option } = Select;

class UserOperations extends Component {
  state = {
    userDetails: {},
    disableClientConciergeAccount: false,
  };

  componentDidMount() {
    let organizationId = JSON.parse(localStorage.getItem("orgId"));
    let orgId = organizationId;
    let query = {};
    // if (userDetails && userDetails["userProfile"] === "A") {
    query = {
      orgId,
      userProfile: "concierge",
    };
    // }
    list(AppConfigs["entityNames"]["users"], query).then((result) => {
      if (result.success) {
        console.log(result.data, "resulttt");
        if (result.data && result.data.length > 0) {
          this.setState({
            disableClientConciergeAccount: true,
          });
        } else {
          this.setState({
            disableClientConciergeAccount: false,
          });
        }
      }
    });
  }

  handleSubmit = (e) => {
    const orgId = JSON.parse(localStorage.getItem("orgId"));
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        let senderConfig =
          "https://6ob4vhh0w5.execute-api.us-east-1.amazonaws.com/dev".indexOf(
            "dev"
          ) > -1
            ? "poweredbyagents.com"
            : orgDetails["websiteName"];
        let welcomeEmailData = {};
        if (values["userProfile"] === "AG") {
          welcomeEmailData = {
            webDomainName: window.location.hostname,
            orgnizationLogo: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgDetails?.Header?.headerLogo}`,
            footerBackgrounndColor:
              orgDetails["footer"]["copyRight"]["backgroundColor"],
            footerTextColor:
              orgDetails["footer"]["copyRight"]["lastFootertextColor"],
            faceBookUrl: orgDetails["footer"]["tabs"]["fbUrl"],
            twitterUrl: orgDetails["footer"]["tabs"]["twitterUrl"],
            youtubeUrl: orgDetails["footer"]["tabs"]["youtubeUrl"],
            peterestUrl: "https://in.pinterest.com/",
            instgramUrl: orgDetails["footer"]["tabs"]["instaUrl"],
            newUserId: values["email"],
            newUserPassword: values["password"],
            orgnizationName: orgDetails["orgName"],
            websiteUrl: "https://" + orgDetails["websiteName"],
            senderEmail: orgDetails["orgEmailAddress"] + "@" + senderConfig,
          };
        }
        let data = {
          entityData: {
            firstName: values["firstName"],
            lastName: values["lastName"],
            email: values["email"].toLowerCase(),
            phone: values["phone"],
            userProfile: values["userProfile"],
            recordStatus: true,
            isActive: true,

            // AGENT WELCOM EMAIL DATA
            welcomeEmailData,
          },
        };
        data["entityData"]["orgId"] = orgId;
        data["entityData"]["password"] = values["password"]
        let userName = values["email"].toLowerCase();
        let password = values["password"];
        this.addUser(userName, password, data);
      }
    });
  };

  addUser = async (email, password, regData) => {
    // await Auth.signUp({
    //   username: email,
    //   password: password,
    // })
    //   .then((data) => {
    // regData["entityData"]["id"] = data.userSub;
    noAuthAdd(AppConfigs["entityNames"]["confirmaUserSingup"], regData).then(
      (result) => {
        if (result.success) {
          message.success(`User created successfully.`);
          this.props.history.push("/application/users");
        } else {
          console.log(result.errors);
        }
      }
    );
    // })
    // .catch((err) => {
    //   if (err.code === "InvalidPasswordException") {
    //     alert(
    //       "Invalid Password: \n- Minimum password length:6\n- Require at least one uppercase letter\n- Require at least one lowercase letter\n- Require at least one number\n- Require at least one nonalphanumeric character"
    //     );
    //     this.setState({ value: true, loading: false });
    //   } else {
    //     alert(err.message);
    //     this.setState({ value: true, loading: false });
    //   }
    // });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    const { disableClientConciergeAccount } = this.state;
    let passwordCriteria = (
      <div>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            -Minimum password length: 6 <br />
            -Required at least one uppercase letter
            <br />
            -Required at least one lowercase letter
            <br />
            -Required at least one number
            <br />
            -Required at least one nonalphanumeric character
            <br />
          </Col>
        </Row>
      </div>
    );
    return (
      <div style={{ marginTop: "5vw" }}>
        <Row>
          <Col
            xxl={{ span: 8, offset: 8 }}
            xl={{ span: 10, offset: 7 }}
            lg={{ span: 12, offset: 6 }}
          >
            <Form onSubmit={this.handleSubmit}>
              <Card title="Add User" style={{ borderRadius: "10px" }}>
                <Row gutter={10}>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          First Name
                        </span>
                      }
                    >
                      {getFieldDecorator("firstName", {
                        rules: [
                          {
                            required: true,
                            message: "Please input First Name!",
                          },
                        ],
                      })(<Input className="form_input_height" />)}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Last Name
                        </span>
                      }
                    >
                      {getFieldDecorator("lastName", {
                        rules: [
                          {
                            required: true,
                            message: "Please input Last Name!",
                          },
                        ],
                      })(<Input className="form_input_height" />)}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Email
                        </span>
                      }
                    >
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: false,
                            type: "email",
                            message: "Please input Email!",
                          },
                        ],
                      })(
                        <Input
                          style={{ textTransform: "lowercase" }}
                          className="form_input_height"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Phone
                        </span>
                      }
                    >
                      {getFieldDecorator("phone", {
                        rules: [
                          { required: true, message: "Please input Phone!" },
                        ],
                      })(
                        <NumberFormat
                          className="ant-input-number"
                          placeholder="Phone"
                          format="(###) ###-####"
                          style={{
                            width: "100%",
                            padding: "4px 11px",
                            fontSize: "14px",
                            height: "40px",
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} sx={24}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Profile
                        </span>
                      }
                    >
                      {getFieldDecorator("userProfile", {
                        rules: [
                          {
                            required: true,
                            message: "Please select user profile!",
                          },
                        ],
                      })(
                        <Select>
                          {/* <Option key="A" value="A">Admin</Option> */}
                          <Option key="AG" value="AG">
                            Agent
                          </Option>
                          <Option key="B" value="B">
                            Buyer
                          </Option>
                          <Option key="S" value="S">
                            Seller
                          </Option>
                          <Option key="L" value="L">
                            Lender
                          </Option>
                          <Option key="V" value="V">
                            Vendor
                          </Option>
                          <Option
                            key="C"
                            value="concierge"
                            disabled={disableClientConciergeAccount}
                          >
                            Concierge
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="Formitem"
                      label={<span className="label">Password</span>}
                    >
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                          { validator: this.validateToNextPassword },
                        ],
                      })(
                        <Input.Password
                          placeholder="*********"
                          autoComplete="new-password"
                          className="form_input_height"
                        />
                      )}
                    </Form.Item>
                    <Popover
                      placement="top"
                      title={
                        <h2 style={{ textAlign: "center" }}>
                          Password Criteria
                        </h2>
                      }
                      content={passwordCriteria}
                      trigger="hover"
                    >
                      <span style={{ color: "#20a8d8" }}>Criteria</span>
                    </Popover>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="Formitem"
                      label={<span className="label">Confirm Password</span>}
                    >
                      {getFieldDecorator("confirm", {
                        rules: [
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          { validator: this.compareToFirstPassword },
                        ],
                      })(
                        <Input.Password
                          placeholder="*********"
                          onBlur={this.handleConfirmBlur}
                          className="form_input_height"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#255595",
                        color: "white",
                        marginRight: "30px",
                        padding: "0 20px 0 20px",
                        border: "none",
                        height: "40px",
                      }}
                    >
                      <Link to="/application/users">Cancel</Link>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                      onClick={this.handleSubmit}
                      style={{
                        float: "right",
                        backgroundColor: "#255595",
                        color: "white",
                        padding: "0 20px 0 20px",
                        height: "40px",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      // <div>
      //     <Form className='form-border' onSubmit={this.addUser}>
      //         <Row className='add-lead-button-margin' style={{ marginTop: '20px' }}>
      //             <Col span={16} />
      //             <Col span={2} offset={1} className='add-lead-cancel'>
      //                 <Button onClick={() => { this.props.closeForm(); }}>Cancel</Button>
      //             </Col>
      //             <Col span={2} className='add-lead-save'>
      //                 <Button type="primary" htmlType='submit' loading={this.state.loading}>Save</Button>
      //             </Col>
      //         </Row>
      //         <Row>
      //             <Row>
      //                 <Col span={9} offset={1}>
      //                     <div
      //                         style={{
      //                             margin: '20px 0px 20px 0px',
      //                             borderRadius: 5,
      //                         }}
      //                         className='add-lead border-form-item'
      //                     >
      //                         <Form.Item
      //                             label='First Name'
      //                         >
      //                             <Input label='firstName' id='firstName' value={this.state.userDetails.firstName} required onChange={e => this.handleOnChange(e)} />
      //                         </Form.Item>
      //                     </div>
      //                 </Col>
      //                 <Col span={9} offset={1}>
      //                     <div
      //                         style={{
      //                             margin: '20px 0px 20px 0px',
      //                             borderRadius: 5,
      //                         }}
      //                         className='add-lead border-form-item'
      //                     >
      //                         <Form.Item
      //                             label='Last Name'
      //                         >
      //                             <Input label='lastName' id='lastName' value={this.state.userDetails.lastName} required onChange={e => this.handleOnChange(e)} />
      //                         </Form.Item>
      //                     </div>
      //                 </Col>
      //             </Row>
      //             <Row>
      //                 <Col span={9} offset={1}>
      //                     <div
      //                         style={{
      //                             margin: '20px 0px 20px 0px',
      //                             borderRadius: 5,
      //                         }}
      //                         className='add-lead border-form-item'
      //                     >
      //                         <Form.Item
      //                             label='Email'
      //                         >
      //                             <Input label='Email' id='email' autoComplete="off" value={this.state.userDetails.email} required onChange={e => this.handleOnChange(e)} />
      //                         </Form.Item>
      //                     </div>
      //                 </Col>
      //                 <Col span={9} offset={1}>
      //                     <div
      //                         style={{
      //                             margin: '20px 0px 20px 0px',
      //                             borderRadius: 5,
      //                         }}
      //                         className='add-lead border-form-item'
      //                     >
      //                         <Form.Item
      //                             label='Password'
      //                         >
      //                             <Input type='password' label='password' autoComplete="new-password" id='password' value={this.state.userDetails.password} required onChange={e => this.handleOnChange(e)} />
      //                         </Form.Item>
      //                     </div>
      //                 </Col>
      //             </Row>
      //             <Row>
      //                 <Col span={9} offset={1}>
      //                     <div
      //                         style={{
      //                             margin: '20px 0px 20px 0px',
      //                             borderRadius: 5,
      //                         }}
      //                         className='add-lead border-form-item'
      //                     >
      //                         <Form.Item
      //                             label='Contact Phone'
      //                         >
      //                             <Input label='Contact Phone' id='phone' value={this.state.userDetails.phone} required onChange={e => this.handleOnChange(e)} />
      //                         </Form.Item>
      //                     </div>
      //                 </Col>
      //             </Row>

      //         </Row>
      //     </Form>
      // </div>
    );
  }
}
export default Form.create({ name: "adduser" })(withRouter(UserOperations));
// export default UserOperations;
