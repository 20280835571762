// export default UserOperations;
import React, { Component } from "react";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import {
  Form,
  Table,
  Input,
  Row,
  Col,
  Button,
  Modal,
  Select,
  Card,
  message,
  Popover,
  Switch,
  Upload,
  Spin,
  Menu,
  Dropdown,
} from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  DollarCircleOutlined,
  HeartOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { list, update, noAuthGetDetails, add } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";
import NumberFormat from "react-number-format";
import { Auth, Storage } from "aws-amplify";

const { Option } = Select;
const Search = Input.Search;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tablelength: 24,
      detailswidth: 0,
      subscritpionModal: false,
      selectedUser: "",
      selectedUserProfile: "",
      subscriptionPacks: [],
      users: [],
      totalUsers: [],
      selectedRecord: {},
      defaultProfile: "",
      defaultProfileSubscriptionPack: "",
      searchVal: "",
      showAnalytics: false,
      analyticsData: "",

      vendorsList: [],
      lendersList: [],
      agentsList: [],
      buyersList: [],
      sellersList: [],
      showModal: false,
      totalAgents: "",
      logoImage: "",
      imageLoading: false,
      editOrgData: "",
      webData: {},
      orgWebSiteName:""
    };
  }

  componentDidMount = async () => {
    this.getAgents();
  };

  getAgents = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    list(AppConfigs["entityNames"]["getWebsites"]).then((result) => {
      if (result.success) {
        this.setState({
          totalAgents: result.data,
          dataLoadingStatus: false,
        });
      }
    });
  };

  getUsers = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    let vendorsList = [];
    let lendersList = [];
    let agentsList = [];
    let buyersList = [];
    let sellersList = [];
    list(AppConfigs["entityNames"]["users"]).then((result) => {
      if (result.success) {
        list(AppConfigs["entityNames"]["subscriptionPacks"]).then(
          (subResult) => {
            if (subResult.success) {
              result.data.map((u) => {
                u.userProfile === "V"
                  ? vendorsList.push(u)
                  : u.userProfile === "L"
                  ? lendersList.push(u)
                  : u.userProfile === "AG"
                  ? agentsList.push(u)
                  : u.userProfile === "B"
                  ? buyersList.push(u)
                  : sellersList.push(u);

                if (u["subscriptionPack"]) {
                  let subPack = subResult.data.filter(
                    (s) => s["id"] === u["subscriptionPack"]
                  )[0];
                  u["subscriptionPack"] = subPack && subPack["planTitle"];
                  u["subscriptionValue"] = subPack && subPack["price"];
                } else {
                  u["subscriptionPack"] = "";
                  u["subscriptionValue"] = 0;
                }
                u["userStatus"] = u["recordStatus"] ? "Active" : "In-Active";
                if (u["referedById"]) {
                  let res = result.data.filter(
                    (s) => s["id"] === u["referedById"]
                  )[0];
                  u["referedById"] = res["firstName"] + " " + res["lastName"];
                }
                return u;
              });

              this.setState({
                subscriptionPacks: subResult.data,
                users: result.data,
                totalUsers: result.data,
                dataLoadingStatus: false,
                vendorsList: vendorsList,
                lendersList: lendersList,
                agentsList: agentsList,
                buyersList: buyersList,
                sellersList: sellersList,
              });
            }
          }
        );
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };

  searchData = async (event, searchType, id) => {
    let searcingValue = searchType === "text" ? event.target.value : event;
    let totalUsers = [];
    await this.setState({
      [id]: searcingValue,
    });
    searcingValue = searcingValue.toLowerCase();
    if (searchType === "text") {
      this.setState({
        defaultProfile: "",
        defaultProfileSubscriptionPack: "",
      });
      totalUsers = this.state.users.filter(
        (user) =>
          (user["firstName"] &&
            user["firstName"].toLowerCase().indexOf(searcingValue) > -1) ||
          (user["phno"] &&
            user["phno"].toString().indexOf(searcingValue) > -1) ||
          user["email"].toLowerCase().indexOf(searcingValue) > -1 ||
          user["userProfile"].toLowerCase().indexOf(searcingValue) > -1
      );
    } else {
      console.log(
        this.state.defaultProfileSubscriptionPack,
        this.state.defaultProfile
      );
      if (
        this.state.defaultProfileSubscriptionPack !== "" &&
        this.state.defaultProfile !== ""
      ) {
        totalUsers = this.state.users.filter(
          (user) =>
            user["userProfile"]
              .toLowerCase()
              .indexOf(this.state.defaultProfile.toLowerCase()) > -1 &&
            user["subscriptionValue"] ===
              this.state.defaultProfileSubscriptionPack
        );
      } else if (
        this.state.defaultProfileSubscriptionPack === "" &&
        this.state.defaultProfile === ""
      ) {
        totalUsers = this.state.users;
      } else if (searchType === "subscription") {
        if (
          this.state.defaultProfileSubscriptionPack === "" &&
          this.state.defaultProfile !== ""
        ) {
          totalUsers = this.state.users.filter(
            (user) =>
              user["userProfile"]
                .toLowerCase()
                .indexOf(this.state.defaultProfile.toLowerCase()) > -1
          );
        } else {
          totalUsers = this.state.users.filter(
            (user) => user["subscriptionValue"] === searcingValue
          );
        }
      } else {
        if (
          this.state.defaultProfile === "" &&
          this.state.defaultProfileSubscriptionPack !== ""
        ) {
          totalUsers = this.state.users.filter(
            (user) =>
              user["subscriptionValue"] ===
              this.state.defaultProfileSubscriptionPack
          );
        } else {
          totalUsers = this.state.users.filter(
            (user) =>
              user["userProfile"].toLowerCase().indexOf(searcingValue) > -1
          );
        }
      }
    }
    // } else {
    //   totalUsers = this.state.users
    // }
    this.setState({
      totalUsers: totalUsers,
    });
  };

  ordersSort(pagination, filters, sorter) {
    let totalUsers = this.state.totalUsers;
    if (sorter["order"] === "descend") {
      if (sorter["columnKey"] === "subscriptionPack") {
        totalUsers = totalUsers.sort((a, b) =>
          parseInt(a["subscriptionValue"]) < parseInt(b["subscriptionValue"])
            ? 1
            : -1
        );
      } else if (sorter["columnKey"] === "recordStatus") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userStatus"] < b["userStatus"] ? 1 : -1
        );
      } else if (sorter["columnKey"] === "fullName") {
        let firstNameUsers = totalUsers
          .filter((u) => u["firstName"])
          .sort((a, b) =>
            a["firstName"].toLowerCase() < b["firstName"].toLowerCase() ? 1 : -1
          );
        totalUsers = [
          ...firstNameUsers,
          ...totalUsers.filter((u) => !u["firstName"]),
        ];
        // totalUsers = totalUsers.sort((a, b) => a["firstName"] && (a["firstName"].toLowerCase() < b["firstName"].toLowerCase()) ? 1 : -1)
      } else if (sorter["columnKey"] === "referedById") {
        let referedByIdUsers = totalUsers
          .filter((u) => u["referedById"])
          .sort((a, b) =>
            a["referedById"].toLowerCase() < b["referedById"].toLowerCase()
              ? 1
              : -1
          );
        totalUsers = [
          ...referedByIdUsers,
          ...totalUsers.filter((u) => !u["referedById"]),
        ];
      } else if (sorter["columnKey"] === "userProfile") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userProfile"].toLowerCase() < b["userProfile"].toLowerCase()
            ? 1
            : -1
        );
      }
    } else if (sorter["order"] === "ascend") {
      if (sorter["columnKey"] === "subscriptionPack") {
        totalUsers = totalUsers.sort((a, b) =>
          parseInt(a["subscriptionValue"]) > parseInt(b["subscriptionValue"])
            ? 1
            : -1
        );
      } else if (sorter["columnKey"] === "recordStatus") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userStatus"] > b["userStatus"] ? 1 : -1
        );
      } else if (sorter["columnKey"] === "fullName") {
        let firstNameUsers = totalUsers
          .filter((u) => u["firstName"])
          .sort((a, b) =>
            a["firstName"].toLowerCase() > b["firstName"].toLowerCase() ? 1 : -1
          );
        totalUsers = [
          ...firstNameUsers,
          ...totalUsers.filter((u) => !u["firstName"]),
        ];
      } else if (sorter["columnKey"] === "referedById") {
        let referedByIdUsers = totalUsers
          .filter((u) => u["referedById"])
          .sort((a, b) =>
            a["referedById"].toLowerCase() > b["referedById"].toLowerCase()
              ? 1
              : -1
          );
        totalUsers = [
          ...referedByIdUsers,
          ...totalUsers.filter((u) => !u["referedById"]),
        ];
      } else if (sorter["columnKey"] === "userProfile") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userProfile"].toLowerCase() > b["userProfile"].toLowerCase()
            ? 1
            : -1
        );
      }
    }
    this.setState({
      totalUsers: totalUsers,
    });
  }

  addSubscription = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["subscriptionPack"],
      (err, values) => {
        if (!err) {
          this.setState({
            dataLoadingStatus: true,
          });
          console.log(values);
          // return;
          let data = {
            entityData: {
              subscriptionPack: values["subscriptionPack"],
            },
          };
          update(
            AppConfigs["entityNames"]["users"],
            this.state.selectedUser,
            data
          ).then((result) => {
            if (result.success) {
              this.setState({
                subscritpionModal: false,
              });
              this.getUsers();
            } else {
              console.log(result.errors);
            }
          });
        }
      }
    );
  };

  addAdmin = async (e) => {
    console.log("add admin for org");
    this.props.form.validateFieldsAndScroll(
      [
        "firstName",
        "lastName",
        "email",
        "phone",
        "websiteName",
        "password",
        "orgName",
        "logo",
        "facebookAppId",
        'orgEmailAddress'
      ],
      (err, values) => {
        if (!err) {
          this.setState({
            dataLoadingStatus: true,
          });

          const webData = this.state.webData;
          webData["Header"]["headerLogo"] = this.state.logoImage;
          let data = {
            entityData: {
              logo: this.state.logoImage,
              firstName: values["firstName"],
              lastName: values["lastName"],
              email: values["email"],
              phone: values["phone"],
              websiteName: values["websiteName"],
              adminWebsite: "admin." + values["websiteName"],
              orgName: values["orgName"],
              isActive: true,
              isNew: true,
              orgEmailAddress: values["orgEmailAddress"],
              facebookAppId: values["facebookAppId"],

//WEBSITE CUSTOMIZE DATA

              Header: webData["Header"],
              WhyJoinUsPage: webData["WhyJoinUsPage"],
              aboutUsPage: webData["aboutUsPage"],
              additionalData: webData["additionalData"],
              contactUsPage: webData["contactUsPage"],
              footer: webData["footer"],
              landingScreen: webData["landingScreen"],
              loginScreen: webData["loginScreen"],
              
            },
          };
          this.addWebsite(values["email"], values["password"], data);
        }
      }
    );
  };

  // ADD NEW ORGANIZATION
  addWebsite = async (email, password, regData) => {
    await Auth.signUp({
      username: email,
      password: password,
    })
      .then((data) => {
        regData["entityData"]["userId"] = data.userSub;
        add(AppConfigs["entityNames"]["website"], regData).then((result) => {
          if (result.success) {
            message.success(`Admin created successfully.`);
            this.setState({ showModal: false, dataLoadingStatus: false });
            this.getAgents();
          } else {
            console.log(result.errors);
            this.setState({ dataLoadingStatus: false });
          }
        });
      })
      .catch((error) => {
        console.log(error, "errrr");
        alert(error.message);
        this.setState({ dataLoadingStatus: false });
      });
  };

  updateAdmin = async (recordId) => {
    this.props.form.validateFieldsAndScroll(
      ["firstName", "lastName", "email", "phone", "websiteName", "orgName","orgEmailAddress", "facebookAppId"],
      (err, values) => {
        if (!err) {
          const webData = this.state.webData;
          webData["Header"]["headerLogo"] = this.state.logoImage;
          this.setState({
            dataLoadingStatus: true,
          });
          let data = {
            entityData: {
              logo: this.state.logoImage,
              firstName: values["firstName"],
              lastName: values["lastName"],
              email: values["email"],
              phone: values["phone"],
              // websiteName: values["websiteName"],
              orgName: values["orgName"],
              isActive: true,
              userProfile: "A",
              facebookAppId: values["facebookAppId"],
              // adminWebsite: "admin." + values["websiteName"],
              orgEmailAddress: values["orgEmailAddress"],
              Header: webData["Header"],
            },
          };

          this.editOrganizationDetails(data, recordId);
        }
      }
    );
  };

  // EDIT ORGANIZATION DETAILS
  editOrganizationDetails = (record, id) => {
    update(AppConfigs["entityNames"]["website"], id, record).then((result) => {
      if (result.success) {
        this.getAgents();
        this.setState({
          editOrgData: "",
          showModal: false,
          dataLoadingStatus: false,
        });
      } else {
        console.log(result.errors);
        this.setState({
          editOrgData: "",
          showModal: false,
          dataLoadingStatus: false,
        });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  websiteSenderEmailVarify = (rule,e,callback)=>{
    const { form } = this.props;
    if(form.getFieldValue("orgEmailAddress").indexOf("@") > -1){
      callback("Please don't use special @ character ")
    }else{
      callback();
    }
  }

  resultView = (icon, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        <Col span={24}>
          <h1 style={{ textAlign: "center" }}>
            {icon === "homes" ? (
              <HomeOutlined className="ant-icon" />
            ) : icon === "buyers" ? (
              <TeamOutlined className="ant-icon" />
            ) : icon === "agents" ? (
              <UserSwitchOutlined className="ant-icon" />
            ) : icon === "lenders" ? (
              <DollarCircleOutlined className="ant-icon" />
            ) : icon === "market" ? (
              <ShopOutlined className="ant-icon" />
            ) : (
              <HeartOutlined className="ant-icon" />
            )}
          </h1>
        </Col>
        <Col span={24}>
          <div>
            <h4 className="sub-title">{title}</h4>
            <h1 className="result-big">{result}</h1>
          </div>
        </Col>
      </Card>
    );
  };

  handleUploadPic = async (info) => {
    this.setState({
      imageLoading: true,
    });
    switch (info.file.status) {
      case "done":
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                console.log(result.key, "result.key");
                this.setState({
                  logoImage: result.key,
                  imageLoading: false,
                });
              });
            } else {
              this.setState({
                imageLoading: false,
              });
            }
          } catch (err) {
            console.log(err, "errr");
          }
        }
        break;
      default:
    }
  };

  handleStatus = (id, activeStatus) => {
    console.log(id, activeStatus);
    this.setState({ dataLoadingStatus: true });
    this.updateStatus(id, activeStatus);
  };

  updateStatus = (id, activeStatus) => {
    let data = {
      entityData: {
        isActive: activeStatus,
      },
    };
    update("websitesData", id, data)
      .then((result) => {
        console.log(result);
        if (result.success) {
          message.success("Updated Successfully");
          this.setState({
            dataLoadingStatus: false,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error");
        this.setState({
          dataLoadingStatus: false,
        });
      });
  };
  getOrgWebData = async (websiteName) => {
    let webData = await noAuthGetDetails("getWebsitesData", websiteName);
    webData = webData.data.data;
    this.setState({
      webData,
    });
  };
  editCell = (editOrgData) => {
    this.getOrgWebData(editOrgData?.websiteName);
    this.setState({
      logoImage: editOrgData?.logo,
      showModal: true,
      editOrgData,
    });
    this.props.form.setFieldsValue({
      firstName: `${editOrgData?.firstName ? editOrgData.firstName : ""}`,
      lastName: `${editOrgData?.lastName ? editOrgData?.lastName : ""}`,
      email: `${editOrgData?.email ? editOrgData?.email : ""}`,
      phone: `${editOrgData?.phone ? editOrgData?.phone : ""}`,
      orgName: `${editOrgData?.orgName ? editOrgData?.orgName : ""}`,
      websiteName: `${
        editOrgData?.websiteName ? editOrgData?.websiteName : ""
      }`,
      facebookAppId:`${
        editOrgData?.facebookAppId ? editOrgData?.facebookAppId : ""
      }`,
      orgEmailAddress:`${
        editOrgData?.orgEmailAddress ? editOrgData?.orgEmailAddress : ""
      }`,
    });
  };

  handleEmptyForm = () => {
    this.getOrgWebData(window.location.hostname);
    this.props.form.setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      websiteName: "",
      orgName: "",
      orgEmailAddress:"",
      facebookAppId:""
    });
    this.setState({ logoImage: "",orgWebSiteName:"" });
  };

  render() {
    const { totalAgents, showModal, imageLoading, logoImage, editOrgData,orgWebSiteName } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    let passwordCriteria = (
      <div>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            -Minimum password length: 6 <br />
            -Required at least one uppercase letter
            <br />
            -Required at least one lowercase letter
            <br />
            -Required at least one number
            <br />
            -Required at least one nonalphanumeric character
            <br />
          </Col>
        </Row>
      </div>
    );
    const columns = [
      {
        title: "Organizations",
        dataIndex: "orgName",
        key: "orgName",
        show: true,
        sorter: true,
        render: (orgName) => {
          return <span>{orgName}</span>;
        },
      },
      {
        title: "FirstName",
        dataIndex: "firstName",
        key: "firstName",
        show: true,
        sorter: true,
        render: (firstName) => {
          return <span>{firstName}</span>;
        },
      },
      {
        title: "lastName",
        dataIndex: "lastName",
        key: "lastName",
        show: true,
        sorter: true,
        render: (lastName) => {
          return <span>{lastName}</span>;
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        show: true,
        render: (phone) => {
          return (
            <span>
              {
                <NumberFormat
                  format="(###) ###-####"
                  value={phone}
                  displayType={"text"}
                />
              }
            </span>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        show: true,
        render: (email) => {
          return <span>{email}</span>;
        },
      },

      {
        title: "Website",
        dataIndex: "websiteName",
        key: "websiteName",
        show: true,
        sorter: true,
        render: (websiteName) => {
          return <span>{websiteName}</span>;
        },
      },

      {
        title: "Status",
        key: "isActive",
        dataIndex: "isActive",
        sorter: true,
        render: (isActive, record) => {
          return (
            <span>
              <Switch
                defaultChecked={isActive === true ? true : false}
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                onChange={(e) => this.handleStatus(record.id, e)}
              />
            </span>
          );
        },
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (_, record) => (
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            className="orders-dropdown"
          >
            <Button>...</Button>
          </Dropdown>
        ),
      },
    ];
    const menu = (record) => (
      <Menu>
        <Menu.Item className="blue" onClick={() => this.editCell(record)}>
          <span style={{ color: "#40a9ff", cursor: "pointer" }}>Edit</span>
        </Menu.Item>
      </Menu>
    );
    return (
      // <div>
      //   <div className="filter">
      <div className="registration">
        <div className="filter">
          <Row style={{ marginTop: "2vw" }}>
            <Row gutter={[16, 16]} style={{ marginBottom: "1vw" }}>
              <Col span={7}>
                <Search
                  className="search"
                  size="large"
                  placeholder="Search"
                  onChange={(event) =>
                    this.searchData(event, "text", "searchVal")
                  }
                  value={this.state.searchVal}
                />
              </Col>
              <Col span={7} style={{ display: "flex" }}></Col>

              <Col span={6} style={{ display: "flex" }}></Col>
              <Col span={4} style={{ display: "flex", float: "right" }}>
                <Button
                  type="primary"
                  size="default"
                  style={{ width: "100%", height: "40px" }}
                  onClick={() => {
                    this.setState({ showModal: true, editOrgData: "" }, () =>
                      this.handleEmptyForm()
                    );
                  }}
                >
                  <h1 style={{ color: "#fff", marginTop: "5px" }}>
                    <i className="fa fa-plus-circle"></i> Admin{" "}
                  </h1>
                </Button>
              </Col>
            </Row>

            <Col span={24} style={{ overflow: "auto" }}>
              <Table
                columns={columns}
                dataSource={this.state.totalAgents}
                loading={this.state.dataLoadingStatus}
                rowKey={(record) => record.id}
                onChange={(pagination, filters, sorter) => {
                  this.ordersSort(
                    pagination,
                    filters,
                    sorter,
                    this.state.totalUsers
                  );
                }}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "25", "50", "100"],
                  total: totalAgents.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </Col>
          </Row>
        </div>

        <Form>
          <Modal
            visible={this.state.subscritpionModal}
            closable={true}
            footer={[
              <Button
                key="cancel"
                className="cancel"
                onClick={() => {
                  this.setState({ subscritpionModal: false });
                }}
              >
                Cancel
              </Button>,
              <Button
                type="primary"
                key="editSubmit"
                htmlType="submit"
                loading={this.state.dataLoadingStatus}
                onClick={this.addSubscription}
              >
                Save
              </Button>,
            ]}
          >
            <div>
              <Row>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "right" }}
                >
                  {/* <CloseOutlined onClick={()=>{this.setState({subscritpionModal: false})}} style={{ float: 'right', cursor: "pointer", fontSize: '1.4em' }} /> */}
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24} sx={24}>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Subscription Pack
                      </span>
                    }
                  >
                    {getFieldDecorator("subscriptionPack", {
                      rules: [
                        { required: true, message: "Please select pack!" },
                      ],
                    })(
                      <Select style={{ width: "100%" }}>
                        {this.state.subscriptionPacks
                          .filter(
                            (s) => s.userType === this.state.selectedUserProfile
                          )
                          .map((s) => (
                            <Option key={s.id} value={s.id}>
                              {s.price === 0 || s.price === "0"
                                ? "Free"
                                : s.price !== undefined && `$ ${s.price}/month`}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Modal>
        </Form>

        <Form>
          <Modal
            visible={showModal}
            closable={true}
            footer={[
              <Button
                key="cancel"
                className="cancel"
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                Cancel
              </Button>,
              <Button
                type="primary"
                key="editSubmit"
                htmlType="submit"
                loading={this.state.dataLoadingStatus}
                onClick={
                  editOrgData !== ""
                    ? () => this.updateAdmin(editOrgData?.id)
                    : this.addAdmin
                }
              >
                {editOrgData !== "" ? "Update" : "Save"}
              </Button>,
            ]}
            onCancel={() => {
              this.setState({ showModal: false, editOrgData: "" });
            }}
          >
            <div>
              <Row>
                <Row>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Website Logo
                      </span>
                    }
                  >
                    {getFieldDecorator("logo", {
                      rules: [
                        { required: true, message: "Please upload a image!" },
                      ],
                    })(
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        style={{
                          borderRadius: "50%",
                          width: "100%",
                          height: "100%",
                        }}
                        showUploadList={false}
                        customRequest={dummyRequest}
                        onChange={(e) => this.handleUploadPic(e)}
                      >
                        <Spin spinning={imageLoading}>
                          {logoImage ? (
                            <img
                              src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${logoImage}`}
                              alt="avatar"
                              style={{
                                width: "100px",
                                height: "100%",
                              }}
                            />
                          ) : (
                            <CameraOutlined className="camera-icon" />
                          )}
                        </Spin>
                      </Upload>
                    )}
                  </Form.Item>
                </Row>

                <Row>
                  <Col xl={{ span: 11 }}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          First Name
                        </span>
                      }
                    >
                      {getFieldDecorator("firstName", {
                        rules: [
                          {
                            required: true,
                            message: "Please input First Name!",
                          },
                        ],
                        initialValue: `${
                          editOrgData?.firstName ? editOrgData.firstName : ""
                        }`,
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 11, offset: 1 }}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Last Name
                        </span>
                      }
                    >
                      {getFieldDecorator("lastName", {
                        rules: [
                          {
                            required: true,
                            message: "Please input Last Name!",
                          },
                        ],
                        initialValue: `${
                          editOrgData?.lastName ? editOrgData?.lastName : ""
                        }`,
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xl={{ span: 11 }}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Email
                        </span>
                      }
                    >
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: true,
                            type: "email",
                            message: "Please input Email!",
                          },
                        ],
                        initialValue: `${
                          editOrgData?.email ? editOrgData?.email : ""
                        }`,
                      })(<Input readOnly={editOrgData ? true : false} />)}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 11, offset: 1 }}>
                    <Form.Item
                      className="Formitem"
                      label={
                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                          Phone
                        </span>
                      }
                    >
                      {getFieldDecorator("phone", {
                        rules: [
                          {
                            required: true,
                            message: "Please input Phone!",
                          },
                        ],
                        initialValue: `${
                          editOrgData?.phone ? editOrgData?.phone : ""
                        }`,
                      })(
                        <NumberFormat
                          className="ant-input-number"
                          placeholder="Phone"
                          format="(###) ###-####"
                          style={{
                            width: "100%",
                            padding: "4px 11px",
                            fontSize: "14px",
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Col span={24}>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Organization
                      </span>
                    }
                  >
                    {getFieldDecorator("orgName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input orgName!",
                        },
                      ],
                      initialValue: `${
                        editOrgData?.orgName ? editOrgData?.orgName : ""
                      }`,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Website
                      </span>
                    }
                  >
                    {getFieldDecorator("websiteName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input websiteName!",
                        },
                      ],
                      initialValue: `${
                        editOrgData?.websiteName ? editOrgData?.websiteName : ""
                      }`,
                    })(<Input readOnly={editOrgData !=="" ? true : false} onChange={(e)=>this.setState({orgWebSiteName:e.target.value})} />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Website Email
                      </span>
                    }
                  >
                    <Popover title="This email used for sending emails from website">
                      {getFieldDecorator("orgEmailAddress", {
                        rules: [
                          {
                            required: true,
                            message: "Please input Website Email!",
                          },
                          { validator: this.websiteSenderEmailVarify },
                        ],
                        initialValue: `${
                          editOrgData?.orgEmailAddress ? editOrgData?.orgEmailAddress : ""
                        }`,
                      })(<Input addonAfter= {(editOrgData && "websiteName" in editOrgData === true && editOrgData?.websiteName !== "") 
                                              ? "@" + editOrgData?.websiteName.replace("www.","")
                                              :orgWebSiteName && "@" + orgWebSiteName.replace("www.","")
                    } />)}
                    </Popover>
                  </Form.Item>
                </Col>
               
                <Col span={24}>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Facebook App Id
                      </span>
                    }
                  >
                    {getFieldDecorator("facebookAppId", {
                      rules: [
                        {
                          required: false,
                          message: "Please input Facebook App Id.!",
                        },
                      ],
                      initialValue: `${
                        editOrgData?.facebookAppId ? editOrgData?.facebookAppId : ""
                      }`,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {editOrgData === "" && (
                  <>
                    <Col span={12} style={{ paddingRight: "1%" }}>
                      <Form.Item
                        className="Formitem"
                        label={<span className="label">Password</span>}
                      >
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                            { validator: this.validateToNextPassword },
                          ],
                        })(
                          <Input.Password
                            placeholder="*********"
                            autoComplete="new-password"
                          />
                        )}
                      </Form.Item>
                      <Popover
                        placement="top"
                        title={
                          <h2 style={{ textAlign: "center" }}>
                            Password Criteria
                          </h2>
                        }
                        content={passwordCriteria}
                        trigger="hover"
                      >
                        <span style={{ color: "#20a8d8" }}>Criteria</span>
                      </Popover>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="Formitem"
                        label={<span className="label">Confirm Password</span>}
                      >
                        {getFieldDecorator("confirm", {
                          rules: [
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            { validator: this.compareToFirstPassword },
                          ],
                        })(
                          <Input.Password
                            placeholder="*********"
                            onBlur={this.handleConfirmBlur}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}
export default Form.create({ name: "addSubscription" })(
  withRouter(organization)
);
