import React, { Component } from "react";
import "antd/dist/antd.css";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Auth } from "aws-amplify";
import LogoMini from "../Images/logomini.png";
import AgentHomes from "../Images/AgentHomes.png";
import AppRoutes from "../Config/AppRoutes";
// import VirtualApplication from "./VirtualOffice/VirtualApplication";

const { Content, Footer, Sider } = Layout;

class Application extends Component {
  rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  onLogout = async () =>
    new Promise((resolve) => {
      Auth.signOut();
      localStorage.clear();
      this.props.history.push("/login");
    });

  render() {
    const { collapsed } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    const tokenGenerated = JSON.parse(localStorage.getItem("token"));
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          className="sidebarMenu"
          // style={{mi nWidth:"240px !important"}}
          width={240}
        >
          <div style={{ borderBottom: "1px solid #002140" }}>
            {userDetails?.userProfile === "A" ? (
              <img
                src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${orgDetails?.Header.headerLogo}`}
                // src={collapsed ? LogoMini : AgentHomes}
                alt="Logo"
                width={collapsed ? "90" : "200px"}
                style={{ padding: "25px" }}
              />
            ) : (
              <img
                src={collapsed ? LogoMini : AgentHomes}
                alt="Logo"
                width={collapsed ? "90" : "200px"}
                style={{ padding: "25px" }}
              />
            )}
          </div>
          {userDetails && (
            <Menu
              theme="dark"
              inlineCollapsed={this.state.collapsed}
              defaultSelectedKeys={
                window.location.pathname === "/application/organization"
                  ? "1"
                  : window.location.pathname === "/application/users"
                  ? "2"
                  : window.location.pathname === "/application/advertisements"
                  ? "3"
                  : window.location.pathname === "/application/contactForm"
                  ? "4"
                  : window.location.pathname === "/application/mortgage"
                  ? "5"
                  : window.location.pathname === "/application/configuration"
                  ? "6"
                  :window.location.pathname === "/application/subscriptions"
                  ? "8"
                  : ["sub1"]
              }
              mode="inline"
              defaultOpenKeys={["sub1"]}
            >
              <Menu.Item key="12">
              <Link to="/application/dashboard" />
              <i className="fa fa-tachometer fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Dashboard</span>
            </Menu.Item>
              {userDetails?.userProfile !== "A" &&
                userDetails?.userProfile === "S" && (
                  <Menu.Item key="1">
                    <Link to="/application/organization" />
                    <i className="fa fa-cogs fa-lg" aria-hidden="true"></i>
                    <span className="menuitem">Organizations</span>
                  </Menu.Item>
                )}
              <Menu.Item key="2">
                <Link to="/application/users" />
                <i className="fa fa-users fa-lg" aria-hidden="true"></i>
                <span className="menuitem">Users</span>
              </Menu.Item>
              {/* <Menu.Item key="10">
                <Link to="/application/all_leads" />
                <i className="fa fa-sitemap fa-lg" aria-hidden="true"></i>
                <span className="menuitem">All Leads</span>
              </Menu.Item> */}
              {userDetails?.userProfile === "A" &&
                userDetails?.userProfile !== "S" &&(
              <Menu.Item key="11">
                <a
                  href={`https://${orgDetails?.websiteName}/login?jwtCode=${tokenGenerated}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fa fa-globe fa-lg" aria-hidden="true"></i>
                  <span className="menuitem">Edit website</span>
                </a>
              </Menu.Item>
              )}
              <Menu.Item key="3">
                <Link to="/application/advertisements" />
                <i
                  className="fa fa-audio-description fa-lg"
                  aria-hidden="true"
                ></i>
                <span className="menuitem">Ads</span>
              </Menu.Item>
              <Menu.Item key="8">
              <Link to="/application/subscriptions" />
              <i className="fa fa-credit-card-alt fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Subscriptions</span>
            </Menu.Item>
              <Menu.Item key="4">
                <Link to="/application/contactForm" />
                <i className="fa fa-address-card fa-lg" aria-hidden="true"></i>
                <span className="menuitem">Contact Form</span>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/application/mortgage" />
                <i className="fa fa-calculator fa-lg" aria-hidden="true"></i>
                <span className="menuitem">Mortgage Cal</span>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/application/configuration" />
                <i className="fa fa-cogs fa-lg" aria-hidden="true"></i>
                <span className="menuitem">APIs</span>
              </Menu.Item>
              <Menu.Item key="7" onClick={this.onLogout}>
                <i className="fa fa-sign-out fa-lg" aria-hidden="true"></i>
                <span className="menuitem">Logout</span>
              </Menu.Item>
            </Menu>
          )}
        </Sider>
        <Layout className="site-layout">
          {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              {AppRoutes.map((route) => (
                <Route
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                  key={route.key}
                />
              ))}
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>PoweredByAgent ©2022</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Application);
