import { Card, Col, Row, Table, Icon, DatePicker } from "antd";
import React, { Component } from "react";
import { list } from "../api/Apis";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Search from "antd/lib/input/Search";
const { RangePicker } = DatePicker;

export default class EmailVisitedContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitedContactList: [],
      dataLoadingStatus: false,
      isShowVisitedContacts: false,
      rangeVisitedList: [],
    };
  }

  componentDidMount() {
    this.handleToShowContacts();
  }

  handleToShowContacts = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    try {
      list("virtualContactLeads", {
        orgId,
        isVisitedContact: true,
      }).then((response) => {
        this.setState({
          visitedContactList: response.data,
          dataLoadingStatus: false,
          rangeVisitedList: response.data,
        });
      });
    } catch (error) {
      console.log(error, "rr");
    }
  };

  handleClickRow = (record) => {
    this.props.history.push(
      `/VirtualOffice/ViewAllContacts/${record.id}`,
      "contacts"
    );
  };

  handleToClickContacts = () => {
    this.setState({
      isShowVisitedContacts: true,
    });
  };

  handleRangePicker = (range, u) => {
    console.log(range, u);
    let visitedContactList = [];
    if (range.length > 0) {
      visitedContactList = this.state.rangeVisitedList.filter((fDate) =>
        moment(
          moment.unix(fDate.lastActiveDate).format("MM/DD/YYYY")
        ).isBetween(u[0], u[1], null, "[]")
      );
    } else {
      visitedContactList = this.state.rangeVisitedList;
    }
    this.setState({
      visitedContactList,
    });
  };

  searchData = (value) => {
    let searchQuery = value.toLowerCase().replace(" ","");
    let filterData = this.state.rangeVisitedList.filter((singleRec) => {
      let val = singleRec.firstName.toLowerCase() + "" + singleRec.lastName.toLowerCase() + "" + singleRec.listingStatus.toLowerCase().replace(" ","") + "" + singleRec.sourceLead.toLowerCase().replace(" ","") +""+ singleRec.emailAddress;
      if(val.indexOf(searchQuery) > -1){
        return singleRec
      }else{
        return false
      }
    });
    console.log(filterData,"000");
    this.setState({
      visitedContactList: filterData,
    });
  };

  render() {
    const { visitedContactList, dataLoadingStatus } = this.state;

    const webColumns = [
      {
        title: "NAME",
        dataIndex: "firstName",
        key: "firstName",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
        render: (firstName, record) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {`${firstName} ${record.lastName}`}
            </span>
          );
        },
      },
      {
        title: "PHONE #",
        dataIndex: "cellPhone",
        key: "cellPhone",
        sorter: false,
        render: (cellPhone) => {
          return (
            <span>
              <NumberFormat
                className="ant-input-number"
                placeholder="Phone"
                value={cellPhone}
                displayType="text"
                format="(###) ###-####"
                style={{ width: "100%", border: "none" }}
              />
            </span>
          );
        },
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
      },
      {
        title: "EMAIL",
        dataIndex: "emailAddress",
        key: "emailAddress",
        sorter: false,
        render: (emailAddress) => {
          return <span>{emailAddress}</span>;
        },
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
      },
      {
        title: "STATUS",
        dataIndex: "listingStatus",
        key: "listingStatus",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
        render: (listingStatus) => {
          return (
            <span
            // style={{
            //   textTransform: "capitalize",
            // }}
            >
              {listingStatus === "Prospective seller or buyer"
                ? "Prospective Seller and Buyer"
                : listingStatus}
            </span>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "typeofProperty",
        key: "typeofProperty",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
        render: (typeofProperty) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {typeofProperty}
            </span>
          );
        },
      },
      {
        title: "LEAD SOURCE",
        dataIndex: "sourceLead",
        key: "sourceLead",
        sorter: false,
        // onCell: (record) => ({
        //   onClick: () => this.this.handleClickRow(record),
        // }),
        render: (sourceLead) => {
          return <span>{sourceLead}</span>;
        },
      },
      {
        title: "LAST ACTIVE",
        dataIndex: "lastActiveDate",
        key: "lastActiveDate",
        sorter: false,
        // (a, b) => new Date(a.lastActiveDate) - new Date(b.lastActiveDate),
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
        render: (lastActiveDate) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              {lastActiveDate
                ? moment.unix(lastActiveDate).format("MM/DD/YYYY hh:mm A")
                : ""}
            </span>
          );
        },
      },
      {
        title: "DATE",
        dataIndex: "registered",
        key: "registered",
        sorter: false,
        // (a, b) => new Date(a.registered) - new Date(b.registered),
        // onCell: (record) => ({
        //   onClick: () => this.handleClickRow(record),
        // }),
        render: (registered) => {
          return (
            <span
            // style={{
            //   textTransform: "capitalize",
            // }}
            >
              {registered ? moment(registered).format("L") : ""}
            </span>
          );
        },
      },
      // {
      //   title: "EDIT",
      //   dataIndex: "id",
      //   key: "id",
      //   sorter: false,
      //   render: (id, record) => {
      //     return (
      //       <span onClick={() => showData(id, record)}>
      //         <EditTwoTone style={{ fontSize: "20px" }} />
      //       </span>
      //     );
      //   },
      // },
    ];

    return (
      <div>
        {window.location.pathname !== "/application/emailVisitedContacts" ? (
          <Card className="mob-card">
            <Row
              justify="space-around"
              style={{ cursor: "pointer" }}
              onClick={this.handleToClickContacts}
            >
              <Link to="/application/emailVisitedContacts">
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 24 }}
                  xs={{ span: 8 }}
                  sm={{ span: 8 }}
                  md={{ span: 8 }}
                  style={{
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <i
                    class="fa fa-address-book dashboard-icons "
                    aria-hidden="true"
                  ></i>
                </Col>
                <Col
                  xxl={{ span: 16 }}
                  xl={{ span: 16 }}
                  lg={{ span: 24 }}
                  xs={{ span: 8 }}
                  sm={{ span: 8 }}
                  md={{ span: 8 }}
                >
                  <div>
                    <h4 className="mid-title">Email Clicks By Contacts</h4>
                    <h1 className="result-small">
                      {visitedContactList.length}
                    </h1>
                  </div>
                </Col>
              </Link>
            </Row>
          </Card>
        ) : (
          <div className="registration">
            <div className="filter">
              <Row style={{ marginTop: "2vw" }}>
                <Row gutter={[16, 16]}>
                  <Col
                    xxl={{ span: 4 }}
                    xl={{ span: 4 }}
                    lg={{ span: 8 }}
                    xs={{ span: 3 }}
                    sm={{ span: 3 }}
                    md={{ span: 3 }}
                  >
                    <div
                      onClick={() => this.props.history.goBack()}
                      style={{
                        backgroundColor: "#f0f2f5",
                        cursor: "pointer",
                        paddingTop: "8px",
                        fontWeight: "600",
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                        <Icon type="left" />
                        Back To Dashboard
                      </span>
                    </div>
                  </Col>
                  <Col
                    xxl={{ span: 7 }}
                    xl={{ span: 7 }}
                    lg={{ span: 16 }}
                    xs={{ span: 7 }}
                    sm={{ span: 7 }}
                    md={{ span: 7 }}
                  >
                    <Search
                      className="search"
                      size="large"
                      placeholder="Search"
                      onChange={(event) => this.searchData(event.target.value)}
                    />
                  </Col>
                  <Col
                    xxl={{ span: 3 }}
                    xl={{ span: 5 }}
                    lg={{ span: 8 }}
                    xs={{ span: 3 }}
                    sm={{ span: 3 }}
                    md={{ span: 3 }}
                    style={{
                      paddingTop: "16px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Filter By Date Range:{" "}
                    </span>
                  </Col>
                  <Col
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 16 }}
                    xs={{ span: 7 }}
                    sm={{ span: 7 }}
                    md={{ span: 7 }}
                  >
                    <RangePicker
                      // defaultValue={[moment('11/22/2022', "MM/DD/YYYY"), moment('11/26/2022', "MM/DD/YYYY")]}
                      format={"MM/DD/YYYY"}
                      onChange={this.handleRangePicker}
                      size={"large"}
                    />
                  </Col>
                  {/* <Col span={7} style={{ display: "flex" }}>
                <Select
                  onChange={(event) =>
                    this.searchData(event, "userProfile", "defaultProfile")
                  }
                  defaultValue="All Status"
                  value={this.state.defaultProfile}
                  notFoundContent={"Not found?!"}
                  style={{ width: "100%" }}
                >
                  <Option key="all" value="all">
                    All Profiles
                  </Option>
                  <Option key="B" value="B">
                    Buyer
                  </Option>
                  <Option key="S" value="S">
                    Seller
                  </Option>
                  <Option key="AG" value="AG">
                    Agent
                  </Option>
                  <Option key="V" value="V">
                    Vendor
                  </Option>
                  <Option key="L" value="L">
                    Lender
                  </Option>
                </Select>
              </Col> */}
                </Row>
                <Col span={24} style={{ overflow: "auto" }}>
                  <Table
                    columns={webColumns}
                    dataSource={visitedContactList}
                    loading={dataLoadingStatus}
                    rowKey={(record) => record.id}
                    // onChange={(pagination, filters, sorter) => {
                    //   this.ordersSort(
                    //     pagination,
                    //     filters,
                    //     sorter,
                    //     this.state.totalUsers
                    //   );
                    // }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}
