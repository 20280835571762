import React, { Component } from "react";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import { Card, Row, Col } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { noAuthList, list } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";
import totalUsers from "../../Images/totalUsers.png";
import EmailVisitedContacts from "../../Components/EmailVisitedContacts";
import PropertiesVisitedList from "../../Components/PropertiesVisitedList";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      activeTabKey: 1,
      referedAgentId: "",
      referedAgentName: "",
      selectedProperty: "",
      completedLeads: "",

      pendingLoading: false,
      inProgressLoading: false,
      enableViewLead: false,
      enableAssignLead: false,
      dataLoadingStatus: false,

      // apiList: [],

      vendorsList: [],
      lendersList: [],
      agentsList: [],
      buyersList: [],
      sellersList: [],

      bigAds: [],
      smallAds: [],

      pendingLeads: [],
      inProgressLeads: [],

      visitorsCount: [],
      emailClicksCount: 0,
    };
  }

  componentDidMount = () => {
    // this.getApiDetails();
    this.getAllUsers();
    this.getAllAds();
    this.getAllLeads();
    this.getIPAddress();
    this.getEmailClicks();
  };

  getEmailClicks = () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    this.setState({
      dataLoadingStatus: true,
    });
    list(AppConfigs["entityNames"]["masterData"], {
      dataType: "emailPropertyVisit",
      orgId,
    }).then((result) => {
      if (result.success) {
        this.setState({
          emailClicksCount:
            result.data.length > 0 ? result.data[0]["emailClicks"] : 0,
          dataLoadingStatus: false,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };
  // getApiDetails = () => {
  //   let orgId = JSON.parse(localStorage.getItem("orgId"));
  //   this.setState({
  //     dataLoadingStatus: true,
  //   });
  //   list(AppConfigs["entityNames"]["masterData"], {
  //     dataType: "apiConfig",
  //     orgId,
  //   }).then((result) => {
  //     if (result.success) {
  //       this.setState({
  //         apiList: result.data.filter(
  //           (state) => state.typeOfListing === "properties"
  //         ),
  //         dataLoadingStatus: false,
  //       });
  //     } else {
  //       this.setState({
  //         dataLoadingStatus: false,
  //       });
  //       console.log(result.errors);
  //     }
  //   });
  // };

  getIPAddress = async () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    noAuthList("ipAddressAnalytics", { orgId }).then((result) => {
      if (result.success) {
        console.log("test", result);
        this.setState({
          dataLoadingStatus: false,
          visitorsCount: result.data,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  getAllUsers = async () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let vendorsList = [];
    let lendersList = [];
    let agentsList = [];
    let buyersList = [];
    let sellersList = [];

    list("users", { orgId }).then((result) => {
      if (result.success) {
        result.data.map((user) => {
          user.userProfile === "V"
            ? vendorsList.push(user)
            : user.userProfile === "L"
            ? lendersList.push(user)
            : user.userProfile === "AG"
            ? agentsList.push(user)
            : user.userProfile === "B"
            ? buyersList.push(user)
            : user.userProfile === "S" && sellersList.push(user);
          return true;
        });

        this.setState({
          dataLoadingStatus: false,
          vendorsList: vendorsList,
          lendersList: lendersList,
          agentsList: agentsList,
          buyersList: buyersList,
          sellersList: sellersList,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  getAllAds = async () => {
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let bigAdsList = [];
    let smallAdsList = [];

    list("ads", { orgId }).then((result) => {
      if (result.success) {
        result.data.map((ads) => {
          ads.imageType === "Square"
            ? smallAdsList.push(ads)
            : bigAdsList.push(ads);
          return true;
        });

        this.setState({
          dataLoadingStatus: false,
          bigAds: bigAdsList,
          smallAds: smallAdsList,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  getAllLeads = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    let orgId = JSON.parse(localStorage.getItem("orgId"));
    let query = {};
    query = {
      orgId: orgId,
      adminDahsboard: true,
    };
    list(`getMissedLeads`, query).then((result) => {
      if (result.success) {
        let pendingList = [];
        let inProgressList = [];
        let completedList = [];

        result.data.filter((lead) => {
          if (lead.isAssigned === false) {
            pendingList.push(lead);
          } else if (
            lead.isAssigned === true &&
            lead.assignedStatus === "pending"
          ) {
            inProgressList.push(lead);
          } else if (lead.assignedStatus === "completed") {
            completedList.push(lead);
          }
          return true;
        });

        this.setState({
          pendingLeads: pendingList,
          inProgressLeads: inProgressList,
          completedLeads: completedList,
          dataLoadingStatus: false,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  resultView = (type, icon, color, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        {type === "leads" || type === "ads" ? (
          <Row justify="space-around">
            <Col
              span={8}
              style={{
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <i
                class={`fa ${icon}`}
                style={{
                  fontSize: "80px",
                  color: color,
                }}
              ></i>
            </Col>
            <Col span={14} offset={2}>
              <div>
                <h4 className={type === "ads" ? "mid-title" : "sub-title"}>
                  {title}
                </h4>
                <h1 className="result-small">{result}</h1>
              </div>
            </Col>
          </Row>
        ) : (
          <div>
            <Col span={24}>
              <h1 style={{ textAlign: "center" }}>
                {icon === "homes" ? (
                  <HomeOutlined className="ant-icon" />
                ) : icon === "buyers" ? (
                  <TeamOutlined className="ant-icon" />
                ) : icon === "agents" ? (
                  <UserSwitchOutlined className="ant-icon" />
                ) : icon === "lenders" ? (
                  <DollarCircleOutlined className="ant-icon" />
                ) : icon === "market" ? (
                  <ShopOutlined className="ant-icon" />
                ) : (
                  // <HeartOutlined className="ant-icon" />
                  <img
                    src={totalUsers}
                    width="65px"
                    height="65px"
                    alt="totalUsers"
                  />
                )}
              </h1>
            </Col>
            <Col span={24}>
              <div>
                <h4 className="sub-title">{title}</h4>
                <h1 className="result-big">{result}</h1>
              </div>
            </Col>
          </div>
        )}
      </Card>
    );
  };
  render() {
    const {
      // apiList,
      vendorsList,
      lendersList,
      agentsList,
      buyersList,
      sellersList,
      bigAds,
      smallAds,
      pendingLeads,
      inProgressLeads,
      completedLeads,
      visitorsCount,
      emailClicksCount,
    } = this.state;
    return (
      <>
        <div>
          <Row
            gutter={[16, 16]}
            style={{ marginTop: "15px" }}
            justify="space-between"
          >
            <Col
              // span={8}
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
            >
              <Card className="mob-card"
              //  bodyStyle={{width:"200px",height:"200px",padding:"0px"}}
              >
                <Row justify="space-around">
                  <Col
                    // span={8}
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 24 }}
                    xs={{ span: 8 }}
                    sm={{ span: 8 }}
                    md={{ span: 8 }}
                    style={{
                      borderRadius: "5px",
                      // padding: "10px",
                    }}
                  >
                    <i
                      class="fa fa-users dashboard-icons"
                      
                    ></i>
                  </Col>
                  <Col 
                  // span={14} offset={2}
                  xxl={{ span: 12 }}
                  xl={{ span: 12 }}
                  lg={{ span: 24 }}
                  xs={{ span: 8 }}
                  sm={{ span: 8 }}
                  md={{ span: 8 }}
                  >
                    <div>
                      <h4 className="mid-title">Total Visitors</h4>
                      <h1 className="result-small">{visitorsCount.count}</h1>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
            >
              <EmailVisitedContacts />
            </Col>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              xs={{ span: 8 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
            >
              <PropertiesVisitedList emailClicksPorp={emailClicksCount} />
            </Col>
          </Row>

          {/* <Row>
            <h1 className="main-title">Users</h1>
          </Row> */}
          <Row>
            <Col span={4}>
              {this.resultView(
                "users",
                "fav",
                "#000",
                "Total users",
                vendorsList.length +
                  lendersList.length +
                  agentsList.length +
                  buyersList.length +
                  sellersList.length
              )}
            </Col>
            <Col span={4}>
              {this.resultView(
                "users",
                "market",
                "#000",
                "Vendors",
                vendorsList.length
              )}
            </Col>
            <Col span={4}>
              {this.resultView(
                "users",
                "lenders",
                "#000",
                "Lenders",
                lendersList.length
              )}
            </Col>
            <Col span={4}>
              {this.resultView(
                "users",
                "agents",
                "#000",
                "Agents",
                agentsList.length
              )}
            </Col>
            <Col span={4}>
              {this.resultView(
                "users",
                "buyers",
                "#000",
                "Buyers",
                buyersList.length
              )}
            </Col>
            <Col span={4}>
              {this.resultView(
                "users",
                "buyers",
                "#000",
                "Sellers",
                sellersList.length
              )}
            </Col>
          </Row>
        </div>

        <div>
          {/* <Row style={{ marginTop: "20px" }}>
            <h1 className="main-title">Advertisements</h1>
          </Row> */}
          <Row style={{ padding: "10px 0 20px" }}>
            <Col span={8}>
              {this.resultView(
                "ads",
                "fa-audio-description",
                "#000",
                "Total Ads",
                bigAds.length + smallAds.length
              )}
            </Col>
            <Col span={8}>
              {this.resultView(
                "ads",
                "fa-arrows-alt",
                "#000",
                "Square Ads",
                smallAds.length
              )}
            </Col>
            <Col span={8}>
              {this.resultView(
                "ads",
                "fa-arrows-h",
                "#000",
                "Rectangle Ads",
                bigAds.length
              )}
            </Col>
          </Row>
        </div>

        <div>
          {/* <Row>
            <h1 className="main-title">Leads</h1>
          </Row> */}
          <Row style={{ padding: "10px 0 20px" }}>
            <Col span={6}>
              {this.resultView(
                "leads",
                "fa-sitemap",
                "#000",
                "Total Leads Count",
                pendingLeads.length +
                  inProgressLeads.length +
                  completedLeads.length
              )}
            </Col>
            <Col span={6}>
              {this.resultView(
                "leads",
                "fa-sitemap",
                "#000",
                "Un-Assigned Leads",
                pendingLeads.length
              )}
            </Col>
            <Col span={6}>
              {this.resultView(
                "leads",
                "fa-sitemap",
                "#000",
                "Assigned Leads",
                inProgressLeads.length
              )}
            </Col>
            <Col span={6}>
              {this.resultView(
                "leads",
                "fa-sitemap",
                "#000",
                "Completed Leads",
                completedLeads.length
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default withRouter(Dashboard);
