import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { getDetails } from "../../api/Apis";
import moment from "moment";

export default function ViewAllContact(props) {
  const [proConatct, setProConatct] = useState("");

  useEffect(() => {
    let id = props.match.params.id;

    getDetails("virtualOfficeContacts", id)
      .then((result) => {
        if (result.success) {
          setProConatct(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.params.id]);

  const handleBack = () => {
    props.history.goBack();
  };
  return (
    <div style={{ padding: "1vw 12vw 1vw 12vw" }}>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            className="font-family-style font-medium-size"
            onClick={handleBack}
          >
            Back to All Leads
          </Button>
        </Col>
      </Row>
      <Card
        className="card_virtual"
        hoverable={true}
        // bodyStyle={{
        //   padding: "0px 24px 24px",
        //   borderTop: "1px solid #ebebeb",
        // }}
      >
        <Row style={{ padding: "1vw" }}>
          {/* <Col span={4}>
            {/* {proConatct?.picture && (
            <Col span={24}>
              <div className="view-lead-lable">Picture</div>
              <div className="view-lead-screen">{proConatct.picture}</div>
            </Col>
          )} 
          </Col> */}
          <Col span={11}>
            {proConatct?.prospectName && (
              <Col span={24}>
                <div className="view-lead-lable">Name</div>
                <div className="view-lead-screen">
                  {proConatct?.prospectName}
                </div>
              </Col>
            )}
            {proConatct?.status && (
              <Col span={24}>
                <div className="view-lead-lable">Status</div>
                <div className="view-lead-screen">{proConatct?.status}</div>
              </Col>
            )}
            {proConatct?.rating && (
              <Col span={24}>
                <div className="view-lead-lable">Rating</div>
                <div className="view-lead-screen">{proConatct?.rating}</div>
              </Col>
            )}
            {proConatct?.address && (
              <Col span={24}>
                <div className="view-lead-lable">
                  {" "}
                  Address(for all sellers & buyers once UAG)
                </div>
                <div className="view-lead-screen">
                  {proConatct.address && proConatct["address"]["citySearch"][0]}
                </div>
              </Col>
            )}

            {proConatct?.primaryEmail && (
              <Col span={24}>
                <div className="view-lead-lable">Primary Email</div>
                <div className="view-lead-screen">
                  {proConatct.primaryEmail}
                </div>
              </Col>
            )}
            {proConatct?.primaryPhone && (
              <Col span={24}>
                <div className="view-lead-lable">Primary Client Phone</div>
                <div className="view-lead-screen">
                  {proConatct.primaryPhone}
                </div>
              </Col>
            )}
            {proConatct?.referredByClient && (
              <Col span={24}>
                <div className="view-lead-lable">
                  Referred by (client referral tracking)
                </div>
                <div className="view-lead-screen">
                  {proConatct.referredByClient}
                </div>
              </Col>
            )}

            {proConatct?.referredByClient && (
              <Col span={24}>
                <div className="view-lead-lable">
                  List Side Price point or UAG $
                </div>
                <div className="view-lead-screen">
                  {proConatct.referredByClient}
                </div>
              </Col>
            )}

            {proConatct?.commission && (
              <Col span={24}>
                <div className="view-lead-lable">Commission %</div>
                <div className="view-lead-screen">{proConatct.commission}</div>
              </Col>
            )}

            {proConatct?.closeDate && (
              <Col span={24}>
                <div className="view-lead-lable">Closing date</div>
                <div className="view-lead-screen">
                  {moment(proConatct.closeDate).format("L")}
                </div>
              </Col>
            )}
            {proConatct?.pulbicNotes && (
              <Col span={24}>
                <div className="view-lead-lable"> Notes on lead(public)</div>
                <div className="view-lead-screen">{proConatct.pulbicNotes}</div>
              </Col>
            )}
          </Col>

          <Col span={11} offset={1}>
            {proConatct?.openDate && (
              <Col span={24}>
                <div className="view-lead-lable">Date</div>
                <div className="view-lead-screen">
                  {moment(proConatct.openDate).format("L")}
                </div>
              </Col>
            )}
            {proConatct?.typeOfClient && (
              <Col span={24}>
                <div className="view-lead-lable">Type of Client</div>
                <div className="view-lead-screen">
                  {proConatct.typeOfClient}
                </div>
              </Col>
            )}
            {proConatct?.town && (
              <Col span={24}>
                <div className="view-lead-lable">Town/Towns</div>
                <div className="view-lead-screen">{proConatct.town}</div>
              </Col>
            )}

            {proConatct?.homeAddress && (
              <Col span={24}>
                <div className="view-lead-lable">
                  {" "}
                  Home address(if not owner occupied)
                </div>
                <div className="view-lead-screen">
                  {proConatct.homeAddress &&
                    proConatct["homeAddress"]["homeCitySearch"][0]}
                </div>
              </Col>
            )}

            {proConatct?.secondaryEmail && (
              <Col span={24}>
                <div className="view-lead-lable">Secondary email</div>
                <div className="view-lead-screen">
                  {proConatct.secondaryEmail}
                </div>
              </Col>
            )}

            {proConatct?.secondaryPhone && (
              <Col span={24}>
                <div className="view-lead-lable">Secondary client phone</div>
                <div className="view-lead-screen">
                  {proConatct.secondaryPhone}
                </div>
              </Col>
            )}
            {proConatct?.sourceOfLead && (
              <Col span={24}>
                <div className="view-lead-lable">Source of lead</div>
                <div className="view-lead-screen">
                  {proConatct.sourceOfLead}
                </div>
              </Col>
            )}

            {proConatct?.buySidePrice && (
              <Col span={24}>
                <div className="view-lead-lable">
                  Buy Side Price point or UAG $
                </div>
                <div className="view-lead-screen">
                  {proConatct.buySidePrice}
                </div>
              </Col>
            )}

            {proConatct?.referralFee && (
              <Col span={24}>
                <div className="view-lead-lable">Referral Fee/TM Fee</div>
                <div className="view-lead-screen">{proConatct.referralFee}</div>
              </Col>
            )}

            {proConatct?.followUpdate && (
              <Col span={24}>
                <div className="view-lead-lable">Follow up date</div>
                <div className="view-lead-screen">
                  {moment(proConatct.followUpdate).format("l")}
                </div>
              </Col>
            )}

            {proConatct?.crm && (
              <Col span={24}>
                <div className="view-lead-lable">Added to CRM</div>
                <div className="view-lead-screen">{proConatct.crm}</div>
              </Col>
            )}

            {proConatct?.privateNotes && (
              <Col span={24}>
                <div className="view-lead-lable">Notes on lead(private)</div>
                <div className="view-lead-screen">
                  {proConatct.privateNotes}
                </div>
              </Col>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
}
