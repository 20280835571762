import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Row,
  Col,
  Popconfirm,
  notification,
  Button,
  Menu,
  Dropdown,
  Card,
} from "antd";
import Amplify, { Storage } from "aws-amplify";
import { list, deleteRecord } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";
import AwsConfig from "../../Config/AwsConfig";
import { Link } from "react-router-dom";
Amplify.configure(AwsConfig);

class Advertisements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tablelength: 24,
      detailswidth: 0,
      users: [],
      totalUsers: [],
      selectedRecord: {},
      resultImage: [],
      data: [],
      newImage: "",
      editCell: false,
      editData: "",
      oldImage: "",
      bigAds: [],
      smallAds: [],
    };
  }
  componentDidMount = async () => {
    this.getUsers();
  };

  details = (record) => {
    this.setState({
      show: false,
      selectedRecord: record,
      tablelength: 14,
      detailswidth: 10,
    });
  };
  close = () => {
    this.setState({
      show: true,
      tablelength: 24,
      detailswidth: 0,
    });
  };

  getUsers = async () => {
    const data = [];
    let bigAdsList = [];
    let smallAdsList = [];

    this.setState({
      dataLoadingStatus: true,
    });
    let query = {};
    let adminDetails = JSON.parse(localStorage.getItem("orgDetails"));
    // if (adminDetails && adminDetails["userProfile"] === "A") {
      query["orgId"] = adminDetails.id;
    // }
    list(AppConfigs["entityNames"]["ads"], query).then(
      (result) => {
        if (result.success) {
          result.data.map(async (each) => {
            each.imageType === "Square"
              ? smallAdsList.push(each)
              : bigAdsList.push(each);

            let newImage = await Storage.get(each.image);
            data.push({
              imageType: each.imageType,
              imageUrl: each.imageUrl,
              whereToshow: each.whereToshow,
              image: each.image,
              imagePath: newImage,
              createdAt: each.createdAt,
              id: each.id,
              updatedAt: each.updatedAt,
              desiredAdOptionPlaces: each.desiredAdOptionPlaces
                ? each.desiredAdOptionPlaces
                : {},
            });
            this.setState({ data, bigAds: bigAdsList, smallAds: smallAdsList });
          });
          this.setState({
            users: result.data,
            data: data,
            dataLoadingStatus: false,
          });
        } else {
          this.setState({
            dataLoadingStatus: false,
          });
          console.log(result.errors);
        }
      }
    );
  };
  getnewDetails = (each) => {
    console.log(each.image, "each");
  };
  editCell = (id) => {
    this.setState({
      editCell: true,
      editData: id,
    });
    localStorage.setItem("editAd", JSON.stringify(id));
    this.props.history.push("/application/addAdvertisement", id);
  };
  handleDelete = (id) => {
    deleteRecord("ads", id).then((result) => {
      if (result.success) {
        this.setState({
          dataLoadingStatus: true,
        });
        notification.open({
          message: "Advertisement deleted",
          description: "Advertisement deleted successfully",
          onClick: () => {
            this.props.history.push("/application/advertisements");
          },
        });
        setTimeout(() => window.location.reload(true), 2000);
      }
    });
  };
  handleToAddnewAdvertisment = () => {
    localStorage.removeItem("editAd");
  };

  resultView = (type, icon, color, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        <Row gutter={[16, 16]}>
          <Col
            span={8}
            style={{
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <i
              class={`fa ${icon}`}
              style={{
                fontSize: "80px",
                color: color,
              }}
            ></i>
          </Col>
          <Col span={14} offset={2}>
            <div>
              <h4 className={type === "ads" ? "mid-title" : "sub-title"}>
                {title}
              </h4>
              <h1 className="result-small">{result}</h1>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const columns = [
      {
        title: "Advertisement type",
        dataIndex: "imageType",
        key: "imageType",
        show: true,
        render: (imageType, record) => {
          return <span>{record.imageType}</span>;
        },
      },
      {
        title: "Image",
        dataIndex: "imagePath",
        key: "imagePath",
        show: true,
        render: (imagePath) => {
          return (
            <div>
              <img src={imagePath} width="70px" height="40px" alt="Logo" />
            </div>
          );
        },
      },
      {
        title: "Destination URL ",
        dataIndex: "imageUrl",
        key: "imageUrl",
        show: true,
        render: (imageUrl, record) => {
          return <span>{imageUrl}</span>;
        },
      },
      {
        title: "Where to show",
        dataIndex: "whereToshow",
        key: "show",
        show: true,
        render: (whereToshow, record) => {
          return (
            <span>
              {typeof whereToshow === "object"
                ? whereToshow.join(", ")
                : whereToshow}
            </span>
          );
        },
      },
      {
        title: "Shown in",
        dataIndex: "desiredAdOptionPlaces",
        key: "generalValue",
        show: true,
        render: (generalValue, record) => {
          return (
            <span>
              {record.desiredAdOptionPlaces.generalData &&
              record.desiredAdOptionPlaces.generalData.length > 0 &&
              record.desiredAdOptionPlaces.generalData[0] === "global"
                ? "Global"
                : record.desiredAdOptionPlaces.generalData}
            </span>
          );
        },
      },
      {
        title: "DETAILS",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (_, record) => (
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            className="orders-dropdown"
          >
            <Button>...</Button>
          </Dropdown>
        ),
      },
    ];
    const menu = (record) => (
      <Menu>
        <Menu.Item className="blue">
          <span
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => this.editCell(record)}
          >
            Edit
          </span>
        </Menu.Item>
        <Menu.Item className="blue">
          <Popconfirm
            title="Are you Sure!! you want to delete this ad?"
            onConfirm={() => this.handleDelete(record.id)}
          >
            <span style={{ cursor: "pointer", color: "#FF0000" }}>Delete</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="registration">
        <div className="filter">
          <Row style={{ marginTop: "2vw" }}>
              <div style={{ display: "flex" }}>
                <h1 className="registrationtext">
                  Advertisements&nbsp;&nbsp;
                  <Link
                    to="/application/addAdvertisement"
                    onClick={this.handleToAddnewAdvertisment}
                  >
                    <i className="fa fa-plus-circle"></i>
                  </Link>
                </h1>
              </div>

              <Col span={24} style={{ overflow: "auto" }}>
                <Table
                  columns={columns.filter((col) => col.show !== false)}
                  dataSource={this.state.data}
                  loading={this.state.dataLoadingStatus}
                  rowKey={(record) => record.id}
                />
              </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default Advertisements;
