import React from "react";
import { Form, Input, Button, Col, Row, notification } from "antd";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { CloseOutlined } from "@ant-design/icons";

class AddZipCodes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zip: "",
      Ziplist: [],
      zipCount: 0,
      agentTowns: [],
      updateZipList: true,
    };
  }

  componentDidMount() {
    this.setState({
      updateZipList: true,
    });
    setTimeout(() => {
      console.log(this.props.oldZiplist, "old zips list");
      this.setState({
        Ziplist: this.props.oldZiplist,
      });
    }, 3000);
  }

  remove = (removeValue) => {
    const { Ziplist } = this.state;
    this.handleDeselectZipTowns(removeValue);
    this.setState({
      Ziplist: Ziplist?.filter((code) => code !== removeValue),
    });
  };

  add = () => {
    this.handleChange();
  };
  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
      });
    }
  };

  handleZipTowns = (address) => {
    console.log(address, "lll");
    try {
      geocodeByAddress(address)
        .then((results) => {
          let address = results[0].address_components;

          let agentTownArray =
            this.state.agentTowns.length > 0 ? this.state.agentTowns : [];
          let townInfo = {
            cityName: "",
            stateName: "",
            zipCode: "",
          };

          for (let i = 0; i < address.length; i++) {
            if (address[i].types[0] === "locality") {
              townInfo["cityName"] = address[i].long_name;
            }
            if (address[i].types[0] === "administrative_area_level_1") {
              townInfo["stateName"] = address[i].short_name;
            }
            if (address[i].types[0] === "postal_code") {
              townInfo["zipCode"] = address[i].long_name;
            }
          }
          agentTownArray.push(townInfo);
          console.log(agentTownArray, "agentTownArrayagentTownArray");
          this.props.handleTotalZipList(agentTownArray, this.state.Ziplist);
          this.setState({
            agentTowns: agentTownArray,
          });
        })
        .catch((error) => console.error("Error", error));
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleDeselectZipTowns = (value) => {
    let tempAgentTowns = this.state.agentTowns;
    let agentTowns = tempAgentTowns.filter(
      (agentTownInfo) => !agentTownInfo.zipCode.includes(value)
    );
    this.setState({
      agentTowns,
    });
    this.props.handleTotalZipList(agentTowns, this.state.Ziplist);
  };

  handleChange = () => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    } else {
      console.log("cpominggg", this.state.zip);
      this.handleZipTowns(this.state.zip);
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };

  render() {
    const { zip, Ziplist } = this.state;

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 0 },
        xl: { span: 24, offset: 0 },
        lg: { span: 9, offset: 0 },
        md: { span: 24, offset: 0 },
      },
    };
    const addButtonLayOut = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 0 },
        xl: { span: 24, offset: 0 },
        lg: { span: 9, offset: 0 },
        md: { span: 24, offset: 0 },
      },
    };

    const formItems = Ziplist.length
      ? Ziplist.map((k, index) => (
          <Form.Item required={false} key={k}>
            <Input
              style={{ width: "75%" }}
              value={k}
              readOnly
              addonAfter={
                // this.state.Ziplist.length >= 1 ? (
                <CloseOutlined
                  className="dynamic-delete-button"
                  onClick={() => this.remove(k)}
                  style={{ color: "red" }}
                />
                // ) : null
              }
            />
          </Form.Item>
        ))
      : "";
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row>
          <Col
            xl={{ span: 18, offset: 0 }}
            lg={{ span: 23, offset: 0 }}
            md={{ span: 23, offset: 0 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Input
                type="number"
                value={zip}
                onChange={this.handleZip}
                className="form-input-profile"
                disabled={this.props.allZipCodes}
              />
            </Form.Item>
          </Col>

          <Col
            xl={{ span: 6, offset: 0 }}
            lg={{ span: 23, offset: 1 }}
            md={{ span: 23, offset: 1 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form.Item {...addButtonLayOut}>
              <Button
                type="primary"
                onClick={this.add}
                style={{
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                  float: "right",
                  background: "#3045d2",
                  borderColor: "#3045d2",
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "16px",
                  height: "50px",
                  width: "100%",
                  cursor:`${this.props.allZipCodes ? "not-allowed" : "pointer"}`
                }}
              >
                Add Zipcode
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: "flex", flexFlow: "row wrap" }}>{!this.props.allZipCodes ? formItems : ""}</Row>
      </Form>
    );
  }
}

export default Form.create({ name: "dynamic_form_item" })(AddZipCodes);
